import { useEffect, useState } from 'react'
import { Grid, Button } from '@mui/material'
import { DropzoneArea } from 'mui-file-dropzone'
import api from '../../api/api'
import { isEmpty } from 'lodash'
const ExtraContentForm = ({ program, setProgram }) => {
	const [filesToUpload, setFilesToUpload] = useState([])
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow(filesToUpload.length !== 0)
	}, [filesToUpload])

	// for dropzones
	const handleDropzoneChange = files => {
		setFilesToUpload(files)
	}

	const handleSubmit = async () => {
		const data = new FormData()
		filesToUpload.map(file => data.append(`extras`, file))
		try {
			const updatedProgram = await api.updateProgramExtras(program._id, data)
			setProgram(updatedProgram)
			setFilesToUpload(
				isEmpty(updatedProgram.attachments) ? [] : updatedProgram.attachments
			)
			window.location.reload()
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<Grid container direction='row'>
			<DropzoneArea
				dropzoneText='click or drag files to add supporting material'
				acceptedFiles={['image/*', 'video/*', 'application/*']}
				style={{
					width: '100%',
					height: '200px',
					marginBottom: '1em',
					border: '2px dashed',
					textAlign: 'center',
					transition: 'border .3s ease-in-out',
					paddingBottom: '5px'
				}}
				showAlerts={false}
				filesLimit={10}
				maxFileSize={10000000}
				onChange={e => handleDropzoneChange(e)}
			/>
			{show && (
				<Button
					type='submit'
					onClick={() => handleSubmit()}
					color='primary'
					style={{ marginTop: '1em', fontWeight: '900' }}
					variant='outlined'
				>
					Upload
				</Button>
			)}
		</Grid>
	)
}
export default ExtraContentForm
