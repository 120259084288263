import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import {
	Avatar,
	Button,
	FormControl,
	Input,
	InputLabel,
	Paper,
	Typography
} from '@mui/material'
import { LockOutlined } from '@mui/icons-material'
import withStyles from '@mui/styles/withStyles'
import API from '../api/api'

const Verify = props => {
	const { token } = useParams()
	const navigate = useNavigate()
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [isValid, setIsValid] = useState(false)

	useEffect(() => {
		setIsValid(
			![password, confirmPassword, token].every(
				item => ![null, undefined, ''].includes(item)
			)
		)
	}, [password, confirmPassword, token, isValid])

	const handleSubmit = async e => {
		e.preventDefault()
		try {
			await API.Verify({ password, confirmPassword }, token, navigate)
		} catch (err) {
			alert('issue verifying password please try again')
			throw err
		}
	}

	const { classes } = props
	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlined />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Please create a password.
				</Typography>
				<form className={classes.form}>
					<FormControl margin='normal' required fullWidth>
						<InputLabel htmlFor='password'>Password</InputLabel>
						<Input
							type='password'
							name='password'
							autoFocus
							required={true}
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
					</FormControl>
					<FormControl margin='normal' required fullWidth>
						<InputLabel htmlFor='confirm-password'>Confirm Password</InputLabel>
						<Input
							name='confirmPassword'
							type='password'
							required={true}
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
						/>
					</FormControl>
					<Button
						fullWidth
						variant='contained'
						color='primary'
						disabled={isValid}
						className={classes.submit}
						onClick={e => handleSubmit(e)}
					>
						Create Password
					</Button>
				</form>
			</Paper>
		</main>
	)
}

Verify.propTypes = {
	classes: PropTypes.object.isRequired
}

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'flex', // Fix IE 11 issue.
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
		margin: '2em',
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '30%',
		padding: '2em'
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
})

export default withStyles(styles)(Verify)
