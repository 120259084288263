import { Component } from 'react'
import {
  Input,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

class ReplaceSpot extends Component {
  render() {
    const { open, handleClose } = this.props
    return (
      <Grid container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Replace Spot</DialogTitle>
          <DialogContent>
            <Input
              autoFocus
              margin='dense'
              id='spot'
              label='Find alternate spot'
              type='file'
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}
export default ReplaceSpot
