import withStyles from '@mui/styles/withStyles'
import { Paper, Typography } from '@mui/material'

const styles = theme => ({
	comment: {
		padding: theme.spacing(1, 2),
		margin: theme.spacing(1, 5)
	},
	tmpg: {
		background: 'lightgrey'
	},
	client: {
		background: 'lightgoldenrodyellow'
	},
	rep: {
		background: '#fccc00'
	}
})

const SpotComment = ({ classes, comment, role, email }) => {
	const classNames = [classes.comment, classes[role]].join(' ')

	return (
		<Paper className={classNames}>
			<Typography component='p'>{comment}</Typography>
			<Typography component='h6' style={{ textAlign: 'right' }}>
				- {email}
			</Typography>
		</Paper>
	)
}

const SpotComments = ({ classes, comments }) => (
	<div>
		{comments.length === 0 ? (
			<Paper className={classes.comment}>
				<Typography component='h6'>No Comments</Typography>
			</Paper>
		) : (
			<div>
				{comments.map((comment, index) => (
					<SpotComment {...comment} key={index} classes={classes} />
				))}
			</div>
		)}
	</div>
)

export default withStyles(styles)(SpotComments)
