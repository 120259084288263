import { Component } from 'react'
import axios from 'axios'

const ID_TOKEN_KEY = 'tmpg-AuthToken'

function saveUser(user) {
  localStorage.setItem('tmpg-user', JSON.stringify(user))
}

function logout() {
  localStorage.removeItem(ID_TOKEN_KEY)
  localStorage.removeItem('tmpg-user')
  axios.defaults.headers.Authorization = ''
}

function requireAuth(nextState, replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/login' })
  }
}

// Get and store id_token in local storage
function setLoginTokens(token) {
  localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(token))
  axios.defaults.headers.Authorization = `Bearer ${token}`
}

function isLoggedIn() {
  const token = JSON.parse(localStorage.getItem(ID_TOKEN_KEY))
  if (token) return true
  else return false
}

function isTMPG() {
  const user = loggedInUser
  return user.role === 'tpmg' ? true : false
}

const loggedInUser = () => {
  if (!isLoggedIn()) return null
  const user = localStorage.getItem('tmpg-user')
  if (user) return JSON.parse(user)
  else return null
}

class LoggedIn extends Component {
  render() {
    return isLoggedIn() ? this.props.children : null
  }
}

class LoggedOut extends Component {
  render() {
    return !isLoggedIn() ? this.props.children : null
  }
}

class TMPG extends Component {
  render() {
    return isTMPG() ? this.props.children : null
  }
}

export {
  logout,
  setLoginTokens,
  isLoggedIn,
  saveUser,
  requireAuth,
  loggedInUser,
  LoggedIn,
  LoggedOut,
  TMPG,
}
