import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import PropTypes from 'prop-types'
import {
	Button,
	FormControl,
	FormGroup,
	FormControlLabel,
	Switch,
	Checkbox,
	Input,
	Select,
	MenuItem,
	InputLabel,
	FormLabel,
	Paper,
	Typography,
	CssBaseline,
	Avatar,
	Grid
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import CreateIcon from '@mui/icons-material/Create'
import api from '../../api/api'
import Markets from './markets'
import SpotRequirements from '../spots/spotRequirements'
import UpdateDates from './updateDates'
import dayjs from 'dayjs'
import { ToastError } from '../notifications/Toasts'

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
			width: 800,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		marginTop: theme.spacing(3)
	},
	noLabel: {
		marginTop: theme.spacing(3)
	}
})

const EditProgram = props => {
	const navigate = useNavigate()
	const { id } = useParams()
	const [program, setProgram] = useState(null)
	const [errorMessages, setErrorMessages] = useState([])
	const [selectedSpots, setSelectedSpots] = useState([])
	const [loading, setLoading] = useState(true)
	const [spots, setSpots] = useState({})
	const [clients, setClients] = useState([])

	useEffect(() => {
		getProgram()
	}, [])

	const getProgram = async () => {
		try {
			const oldProgram = await api.getProgram(id)
			const data = await api.allClients()
			let tempClients = []
			for (let client in data) {
				tempClients.push({ name: data[client].company, id: data[client]._id })
			}
			setClients(tempClients)
			const temp = {
				':15 DJ Endorsements': oldProgram.DJ_15_Endorsements,
				':30 DJ Endorsements': oldProgram.DJ_30_Endorsements,
				':60 DJ Endorsements': oldProgram.DJ_60_Endorsements,
				':15 Brand Spot': oldProgram.Brand_15_Spot,
				':30 Brand Spot': oldProgram.Brand_30_Spot,
				':60 Brand Spot': oldProgram.Brand_60_Spot,
				'Brand Centric Promos': oldProgram.brandCentricPromos,
				'Sweepstakes Promos': oldProgram.sweepstakesPromos,
				'Appearance Promos': oldProgram.appearancePromos,
				'Call-ins': oldProgram.callins,
				'Sponsorship / Brand Mention': oldProgram.sponsorshipBrandMention,
				'N_W_TSpot': oldProgram.N_W_TSpot,
				'Vignettes': oldProgram.vignettes,
				'Interviews': oldProgram.interviews,
				'Custom': oldProgram.custom
			}
			setSpots(temp)
			let requiredSpots = []
			Object.keys(temp).forEach(spot => {
				if (temp[spot].requiredSpot) {
					requiredSpots.push({ [spot]: temp[spot] })
				}
			})
			setSelectedSpots(requiredSpots)
			setProgram({
				name: oldProgram.name,
				product: oldProgram.product,
				status: oldProgram.status,
				aircheck: oldProgram.aircheck,
				clientReview: oldProgram.clientReview,
				variesByMarket: oldProgram.variesByMarket,
				issuingBrand: oldProgram.issuingBrand,
				client: oldProgram.client,
				markets: oldProgram.markets,
				stations: oldProgram.stations,
				rotationDates: oldProgram.rotationDates,
				attachments: oldProgram.extras
			})
			setLoading(false)
		} catch (err) {
			ToastError({
				message: 'There was an issue fetching the program—please refresh'
			})
			throw err
		}
	}

	const handleToggle = (name, event) => {
		let temp = { ...program }
		temp[name] = event.target.checked
		setProgram(temp)
		let tempspots = { ...spots }
		tempspots[name].requiredSpot = event.target.checked
		setSpots(tempspots)

		let tempSpots = { ...selectedSpots }
		!selectedSpots.includes(name) && event.target.checked === true
			? tempSpots.push({ [name]: tempspots[name] })
			: (tempSpots = tempSpots.filter(item => Object.keys(item)[0] !== name))
		setSelectedSpots(tempSpots)
	}

	const handleChange = event => {
		let temp = { ...program }
		console.log(temp)
		temp[event.target.name] = event.target.value
		setProgram(temp)
	}

	// for file inputs
	const handleFileInputChange = event => {
		let temp = { ...program }
		temp.attachments = {
			...program.attachments,
			[event.target.name]: [event.target.files[0]]
		}
	}

	const handleObjChange = (event, spotType, field) => {
		let temp = { ...spots }
		if (field === 'dueDate')
			temp[spotType][field] = dayjs(event).format('MM-DD-YYYY')
		else temp[spotType][field] = event.target.value
		setSpots(temp)
	}

	const handleClient = event => {
		let temp = { ...program }
		temp.client = event.target.value
		setProgram(temp)
	}

	const submitType = () => {
		let temp = { ...program }
		temp.status = 'published'
		setProgram(temp)
		onSubmit()
	}
	const setMarkets = val => {
		let temp = { ...program }
		temp.markets = val
		setProgram(temp)
	}
	const setStations = val => {
		let temp = { ...program }
		temp.stations = val
		setProgram(temp)
	}

	const setDates = (date, type, index) => {
		let temp = { ...program }
		temp.rotationDates[index][type] = date
		setProgram(temp)
	}

	const onSubmit = async () => {
		const usableNames = {
			':15 DJ Endorsements': 'DJ_15_Endorsements',
			':30 DJ Endorsements': 'DJ_30_Endorsements',
			':60 DJ Endorsements': 'DJ_60_Endorsements',
			':15 Brand Spot': 'Brand_15_Spot',
			':30 Brand Spot': 'Brand_30_Spot',
			':60 Brand Spot': 'Brand_60_Spot',
			'Brand Centric Promos': 'brandCentricPromos',
			'Sweepstakes Promos': 'sweepstakesPromos',
			'Appearance Promos': 'appearancePromos',
			'Call-ins': 'callins',
			'Sponsorship / Brand Mention': 'sponsorshipBrandMention',
			'N/W/T Spot': 'N_W_TSpot',
			'Vignettes': 'vignettes',
			'Interviews': 'interviews',
			'Custom': 'custom'
		}
		let programAttributes = { ...program }
		Object.keys(spots).forEach(spot => {
			const name = usableNames[spot]
			programAttributes[name] = spots[spot]
		})
		try {
			const res = await api.updateProgram(id, programAttributes)
			navigate(`/view/program/${res._id}`)
		} catch (err) {
			setErrorMessages(err)
			ToastError({
				message:
					'There was an issue updating the program—please review the program for errors'
			})
		}
	}

	const arr = Array.apply(null, { length: 25 }).map(Number.call, Number)

	const spotArray = [
		':15 DJ Endorsements',
		':30 DJ Endorsements',
		':60 DJ Endorsements',
		':15 Brand Spot',
		':30 Brand Spot',
		':60 Brand Spot',
		'Brand Centric Promos',
		'Sweepstakes Promos',
		'Appearance Promos',
		'Call-ins',
		'Sponsorship / Brand Mention',
		'N_W_TSpot',
		'Vignettes',
		'Interviews',
		'Custom'
	]
	const { classes } = props

	if (loading) {
		return (
			<Paper>
				<CssBaseline />
				<Typography component='h1' variant='h5'>
					Fetching the program
				</Typography>
			</Paper>
		)
	} else {
		return (
			<main className={classes.main}>
				<CssBaseline />
				<Paper className={classes.paper}>
					<Avatar className={classes.avatar}>
						<CreateIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Update {program.aircheck ? 'aircheck' : 'program'}
					</Typography>
					<form className={classes.form}>
						{errorMessages.length > 0 &&
							errorMessages.map((message, i) => <h1 key={i}>{message}</h1>)}
						<Grid container>
							<Grid container direction='row'>
								<FormGroup row>
									<FormControlLabel
										control={
											<Switch
												checked={program.aircheck}
												onChange={e => handleToggle('aircheck', e)}
												value='aircheck'
											/>
										}
										label='Aircheck'
									/>
								</FormGroup>
							</Grid>
							<Grid
								container
								direction='row'
								spacing={2}
								style={{ margin: 20 }}
							>
								<Grid item sm={3}>
									<FormControl margin='normal' required fullWidth>
										<InputLabel htmlFor='product'> Product </InputLabel>
										<Input
											autoFocus
											name='product'
											type='text'
											id='product'
											value={program.product}
											onChange={handleChange}
										/>
									</FormControl>
								</Grid>
								<Grid item sm={3}>
									<FormControl margin='normal' required fullWidth>
										<InputLabel htmlFor='name'> Program Name </InputLabel>
										<Input
											name='name'
											type='text'
											value={program.name}
											onChange={handleChange}
										/>
									</FormControl>
								</Grid>
								<Grid item sm={3}>
									<FormControl margin='normal' required fullWidth>
										<InputLabel htmlFor='issuing branc'>
											{' '}
											Issuing Brand{' '}
										</InputLabel>
										<Input
											name='issuingBrand'
											type='text'
											value={program.issuingBrand}
											onChange={handleChange}
										/>
									</FormControl>
								</Grid>
								<Grid item sm={3}>
									<FormControl
										margin='normal'
										required
										fullWidth
										variant='standard'
									>
										<InputLabel htmlFor='client'> Client </InputLabel>
										<Select
											value={program.client}
											renderValue={p => p.name}
											onChange={handleClient}
										>
											{clients.map((client, index) => (
												<MenuItem key={client.name + index} value={client}>
													{client.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction='row'>
								<Markets
									setProgramMarkets={setMarkets}
									setProgramStations={setStations}
									currentStations={program.stations}
									currentMarkets={program.markets}
								/>
							</Grid>
							<Grid
								container
								direction='row'
								style={{ marginTop: 20, marginBottom: 20 }}
							>
								<UpdateDates
									setDates={setDates}
									rotationDates={program.rotationDates}
								/>
							</Grid>
							<Grid
								container
								direction='row'
								style={{ marginTop: 20, marginBottom: 20 }}
							>
								<Grid container direction='row'>
									<FormControl
										component='fieldset'
										className={classes.formControl}
									>
										<FormLabel component='legend'>Select Type(s)</FormLabel>
										<FormGroup row>
											{spotArray.map((spotType, index) => (
												<Grid item sm={4} key={spotType + index}>
													<FormControlLabel
														style={{ marginLeft: 10 }}
														size='small'
														control={
															<Checkbox
																checked={
																	spots[spotType]
																		? spots[spotType].requiredSpot
																		: ''
																}
																onChange={e => handleToggle(spotType, e)}
																value={
																	spots[spotType]
																		? spots[spotType].requiredSpot
																		: ''
																}
															/>
														}
														label={spotType}
													/>
												</Grid>
											))}
										</FormGroup>
									</FormControl>
								</Grid>
								{selectedSpots.map((spotType, index) => (
									<Grid container direction='row' key={index}>
										<SpotRequirements
											attachments={program.attachments}
											props={props}
											vals={spotType[Object.keys(selectedSpots[index])]}
											handleObjChange={handleObjChange}
											handleFileInputChange={handleFileInputChange}
											spotType={Object.keys(selectedSpots[index])}
											array={arr}
										/>
									</Grid>
								))}
							</Grid>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								spacing={2}
							>
								<Grid item>
									{program.status !== 'published' && (
										<Button
											variant='contained'
											color='secondary'
											className={classes.submit}
											onClick={() => submitType('draft')}
										>
											Save Draft
										</Button>
									)}
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										color='primary'
										className={classes.submit}
										onClick={() => submitType('published')}
									>
										Publish
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</main>
		)
	}
}

EditProgram.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(EditProgram)
