import { useState } from 'react'
import {
  Button,
  Input,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import api from '../api/api'

const SubUser = ({ programId, clientId, setRefetch, setOpen, open }) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const onSubmit = async () => {
    try {
      await api.createSubUser({
        firstName,
        lastName,
        email,
        role: 'client',
        clientId,
        programId,
      })
      setOpen(false)
      setRefetch(true)
    } catch (err) {
      throw err
    }
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create new sub user for this program </DialogTitle>
      <DialogContent>
        <FormControl margin='normal' required fullWidth>
          <InputLabel htmlFor='email'> Email Address </InputLabel>
          <Input
            id='email'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl margin='normal' required fullWidth>
          <InputLabel htmlFor='email'> First Name </InputLabel>
          <Input
            id='name'
            name='firstName'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormControl>
        <FormControl margin='normal' required fullWidth>
          <InputLabel htmlFor='email'> Last Name </InputLabel>
          <Input
            id='name'
            name='lastName'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormControl>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          onClick={() => onSubmit()}
        >
          Send {firstName} an Invite
        </Button>
      </DialogContent>
    </Dialog>
  )
}
export default SubUser
