import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import api from '../../api/api'
import PropTypes from 'prop-types'
import {
	CssBaseline,
	Paper,
	Grid,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	Dialog
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { UserContext } from '../../userContext'
import SpotPanel from '../spots/spotPanel'
import ExtraContentForm from './extraContentForm'
import ExtraContentCard from './extraContentCard'
import DatesOfRotation from './datesOfRotation'
import SubUser from '../createSubUser'
import SelectSubUser from '../selectSubUser'
import ClientSelector from '../clientselector'
import { isEmpty } from 'lodash'

const styles = theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		textAlign: 'left'
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	}
})

const Program = props => {
	const navigate = useNavigate()
	const { id, station } = useParams()
	const [program, setProgram] = useState({})
	const [loading, setLoading] = useState(true)
	const [spotsLoading, setSpotsLoading] = useState(true)
	const [spots, setSpots] = useState([])
	const [subUser, setSubUser] = useState([])
	const [stationSelected, setStationSelected] = useState(props.station)
	const [editSubUser, setEditSubUser] = useState(false)
	const [clientOpen, setClientOpen] = useState(false)
	const [openSelect, setOpenSelect] = useState(false)
	const [open, setOpen] = useState(false)

	useEffect(() => {
		setLoading(true)
		getProgram()
	}, [])

	const getProgram = async () => {
		try {
			const res = await api.getProgram(id)
			setProgram(res)
			getSpots()
			setLoading(false)
		} catch (err) {
			throw err
		}
	}

	const getSpots = async () => {
		try {
			let tempSpots = []
			if (stationSelected !== undefined) {
				tempSpots = await api.getSpotsByStation(id, stationSelected)
			} else {
				tempSpots = await api.getSpots(id)
			}
			setSpots(tempSpots)
			setSpotsLoading(false)
		} catch (err) {
			throw err
		}
	}

	const handlePublish = async e => {
		try {
			const res = await api.updateProgram(program._id, { status: 'published' })
			setProgram(res)
		} catch (err) {
			throw err
		}
	}

	const { classes } = props
	if (loading) {
		return <h1> loading...</h1>
	} else {
		return (
			<UserContext.Consumer>
				{({ user }) => (
					<Grid container justifyContent='center'>
						<CssBaseline />
						<Grid item xs={12}>
							<Typography variant='h4' style={{ padding: 15 }}>
								Program: {program.name}
							</Typography>
						</Grid>
						<Grid item sm={12} xs={12} md={10} style={{ marginTop: 30 }}>
							<Paper style={{ padding: '2em' }}>
								<Typography variant='h5' style={{ paddingBottom: '1em' }}>
									Overview
								</Typography>
								<Grid container>
									<DatesOfRotation
										program={program}
										station={stationSelected}
									/>
									{user && user.role === 'tmpg' ? (
										<Grid item sm={12}>
											<Grid item sm={10} style={{ marginBottom: '1em' }}>
												<Typography align='left'>
													{program.client ? (
														<span>
															Client:{' '}
															{program.client.name ? program.client.name : ''}
														</span>
													) : (
														<span>
															Client: no client has been added to this program{' '}
															<Button
																color='primary'
																onClick={() => clientOpen(true)}
																style={{ marginLeft: '2em' }}
															>
																Add Client
															</Button>{' '}
														</span>
													)}
												</Typography>
												<Dialog
													open={clientOpen}
													onClose={() => setClientOpen(false)}
												>
													<DialogTitle>Add Client</DialogTitle>
													<DialogContent>
														<ClientSelector
															programId={program._id}
															setSubUser={setSubUser}
															closeDialog={setClientOpen}
														/>
													</DialogContent>
												</Dialog>
											</Grid>
											<Grid item sm={12} style={{ marginBottom: '1em' }}>
												{program.markets && program.markets !== '' ? (
													<Typography align='left'>
														Markets:{' '}
														{program.markets.map(market => (
															<span key={market} style={{ marginLeft: '.5em' }}>
																{market}{' '}
															</span>
														))}
													</Typography>
												) : (
													''
												)}
											</Grid>
											<Grid item sm={12} style={{ marginBottom: '1em' }}>
												{program.stations && program.stations ? (
													<Typography align='left' sm={12}>
														Stations:
														{program.stations.map(station => (
															<span
																key={station.station}
																style={{
																	marginLeft: '.5em'
																}}
															>
																{station.station}
															</span>
														))}
													</Typography>
												) : (
													''
												)}
											</Grid>
										</Grid>
									) : user.role === 'rep' ? (
										<Typography align='left'>
											Station: {stationSelected}
										</Typography>
									) : (
										''
									)}
								</Grid>
								<SpotPanel
									program={program}
									setSpots={setSpots}
									spots={spots}
									role={user && user.role}
									station={stationSelected}
									spotsLoading={spotsLoading}
								/>
								{user && user.role === 'client' ? (
									<Grid container direction='row'>
										<Typography className={classes.heading}>
											Manage external access
										</Typography>
										<Grid container>
											{program.subUser ? (
												<Grid row>
													{program.subUser.map(subUser => (
														<Grid item>
															<Typography>{subUser.firstName}</Typography>
															<Typography>{subUser.LastName}</Typography>
															<Button onClick={() => setEditSubUser(true)}>
																Remove User from program
															</Button>
														</Grid>
													))}
												</Grid>
											) : (
												<Grid row>
													<Button onClick={() => setOpen(true)}>
														{' '}
														Create new subUser{' '}
													</Button>
													<Button onClick={() => setOpenSelect(true)}>
														{' '}
														Attach existing subUser to program{' '}
													</Button>
													<SubUser
														open={open}
														setOpen={setOpen}
														programId={program._id}
														clientId={user._id}
														subUser={program.subUser}
													/>
													<SelectSubUser
														openSelect={openSelect}
														closeSelect={setOpenSelect}
														programId={program._id}
														clientId={user._id}
													/>
												</Grid>
											)}
										</Grid>
										<Grid
											container
											direction='row'
											style={{ marginBottom: '1em' }}
										>
											<Grid container direction='row'>
												{!isEmpty(program.extras) ? (
													<ExtraContentCard
														extras={program.extras}
														programId={program._id}
													/>
												) : (
													<Typography variant='paragraph'>
														There are no added value assets attached to this
														program{' '}
													</Typography>
												)}
											</Grid>
										</Grid>
									</Grid>
								) : user.role === 'tmpg' ? (
									<Grid>
										<Grid
											container
											direction='row'
											style={{ marginBottom: '1em' }}
										>
											<Grid container direction='row'>
												{!isEmpty(program.extras) ? (
													<ExtraContentCard
														extras={program.extras}
														programId={program._id}
													/>
												) : (
													<Typography variant='paragraph'>
														There are no added value assets attached to this
														program{' '}
													</Typography>
												)}
											</Grid>
										</Grid>
										<Grid container direction='row'>
											<Grid item sm={12}>
												<Typography
													className={classes.heading}
													style={{ marginBottom: '1em' }}
												>
													Upload Added Value Assets
												</Typography>
												<Grid item sm={4}>
													<ExtraContentForm
														program={program}
														setProgram={setProgram}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								) : (
									''
								)}
							</Paper>
						</Grid>
						{user && user.role === 'tmpg' ? (
							<Grid
								container
								display='row'
								justifyContent='flex-end'
								style={{ marginRight: '3em', marginTop: '1em' }}
							>
								<Grid item>
									{program.status === 'draft' ? (
										<Button
											style={{ margin: '1em' }}
											color='secondary'
											onClick={() => handlePublish()}
										>
											Publish
										</Button>
									) : (
										''
									)}
									<Button
										style={{ margin: '1em' }}
										color='primary'
										onClick={() => navigate(`/edit/program/${id}`)}
									>
										edit program
									</Button>
								</Grid>
							</Grid>
						) : (
							''
						)}
					</Grid>
				)}
			</UserContext.Consumer>
		)
	}
}
Program.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Program)
