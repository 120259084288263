import { useState, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import api from './api/api'
import { loggedInUser } from './auth/AuthService'
import MenuAppBar from './layout/header'
import SignIn from './auth/login'
import ErrorMessage from './404'
import NoToken from './noToken'
import ResetPassword from './auth/forgot'
import Sent from './auth/sent'
import Stations from './users/stations'
import UsersList from './users/userslist'
import Dashboard from './users/dashboard'
import ClientDashboard from './users/clientDashboard'
import RepDashboard from './users/repDashboard'
import VerifyPassword from './auth/verify'
import NewUser from './users/tmpg/new/new'
import Profile from './users/profile'
import Program from './components/programs/program'
import { UserContext } from './userContext'
import NewProgram from './components/programs/newProgram'
import EditProgram from './components/programs/editProgram'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
	const [user, setUser] = useState(loggedInUser())
	const [ready, setReady] = useState(false)

	useEffect(() => {
		getUser()
	}, [])

	const getUser = async () => {
		try {
			await api.updateUser()
			setUser(loggedInUser())
			setReady(true)
		} catch (err) {
			throw err
		}
	}
	return (
		<div>
			{ready && (
				<UserContext.Provider value={{ user: user, setUser: setUser }}>
					<MenuAppBar user={user} setUser={setUser} />
					<ToastContainer />
					{user ? (
						<Routes>
							{user && user.role === 'rep' ? (
								<Route exact path='/' element={<RepDashboard user={user} />} />
							) : user && user.role === 'client' ? (
								<Route
									exact
									path='/'
									element={<ClientDashboard user={user} />}
								/>
							) : (
								<Route exact path='/' element={<Dashboard user={user} />} />
							)}
							<Route
								exact
								path='/profile'
								element={<Profile user={user} setUser={setUser} />}
							/>
							<Route exact path='/program' element={<NewProgram />} />
							<Route exact path='/view/program/:id' element={<Program />} />
							<Route
								exact
								path='/view/program/:id/station/:station'
								element={<Program />}
							/>
							<Route exact path='/sent' element={<Sent />} />
							<Route exact path='/new' element={<NewUser />} />
							<Route exact path='/edit/program/:id' element={<EditProgram />} />
							<Route exact path='/view/stations' element={<Stations />} />
							<Route exact path='/view/users' element={<UsersList />} />
							<Route exact path='/edit/user/:id' element={<UsersList />} />
							<Route exact path='/edit/station/:id' element={<UsersList />} />
							<Route path='*' element={<ErrorMessage />} />
						</Routes>
					) : (
						<Routes>
							<Route exact path='/' element={<SignIn setUser={setUser} />} />
							<Route exact path='/verify/:token' element={<VerifyPassword />} />
							<Route exact path='/reset' element={<ResetPassword />} />
							<Route exact path='/error' element={<ErrorMessage />} />
							<Route exact path='/noToken' element={<NoToken />} />
							<Route path='*' element={<Navigate to='/' replace />} />
						</Routes>
					)}
				</UserContext.Provider>
			)}
		</div>
	)
}

export default App
