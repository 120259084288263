import { useState } from 'react'
import { useLocation } from 'react-router'
import { Button, FormControl, TextField, FormLabel, Grid } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const SpotRequirements = ({
	spotType,
	handleFileInputChange,
	handleObjChange,
	vals
}) => {
	const location = useLocation()
	const [copy, setCopy] = useState(vals ? vals.script : '')
	const usableNames = {
		':15 DJ Endorsements': 'DJ_15_Endorsements',
		':30 DJ Endorsements': 'DJ_30_Endorsements',
		':60 DJ Endorsements': 'DJ_60_Endorsements',
		':15 Brand Spot': 'Brand_15_Spot',
		':30 Brand Spot': 'Brand_30_Spot',
		':60 Brand Spot': 'Brand_60_Spot',
		'Brand Centric Promos': 'brandCentricPromos',
		'Sweepstakes Promos': 'sweepstakesPromos',
		'Appearance Promos': 'appearancePromos',
		'Call-ins': 'callins',
		'Sponsorship / Brand Mention': 'sponsorshipBrandMention',
		'N_W_TSpot': 'N_W_TSpot',
		'Vignettes': 'vignettes',
		'Interviews': 'interviews',
		'Custom': 'custom'
	}

	const { dueDate, attachment } = vals
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Grid container direction='row' style={{ margin: 20 }}>
				<Grid item sm={12}>
					<FormLabel component='legend' style={{ marginBottom: 0 }}>
						{' '}
						{spotType}{' '}
					</FormLabel>
					<FormControl margin='normal' required fullWidth>
						<TextField
							id='filled-multiline-flexible'
							label='Spot notes'
							multiline
							value={copy}
							onChange={e => setCopy(e.target.value)}
							onBlur={e => handleObjChange(e, spotType, 'script')}
							margin='normal'
						/>
					</FormControl>
				</Grid>
				<Grid container direction='row' spacing={2}>
					<Grid item sm={4}>
						<FormControl margin='normal' required fullWidth>
							<DatePicker
								label='Due date'
								type='date'
								value={dayjs(dueDate)}
								onChange={event => handleObjChange(event, spotType, 'dueDate')}
								InputLabelProps={{
									shrink: true
								}}
							/>
						</FormControl>
					</Grid>
					{location.pathname.includes('edit') && (
						<Grid item sm={4} style={{ marginTop: 30 }}>
							{attachment && (
								<FormControl fullWidth>
									<Button
										color={attachment ? 'info' : 'inherit'}
										component='label'
										variant={attachment ? 'contained' : 'text'}
										style={
											attachment
												? { backgroundColor: '#008100', color: 'white' }
												: {}
										}
										disabled={attachment ? true : false}
									>
										{attachment ? 'Uploaded file' : 'Upload Document'}
										<input
											type='file'
											name={`${usableNames[spotType]}_file`}
											onChange={event =>
												handleFileInputChange(
													event.target.name,
													event.target.files
												)
											}
											style={{ display: 'none' }}
										/>
									</Button>
								</FormControl>
							)}
						</Grid>
					)}
				</Grid>
			</Grid>
		</LocalizationProvider>
	)
}
export default SpotRequirements
