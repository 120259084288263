import { useState, useEffect } from 'react'
import { CssBaseline, Grid, Button, Typography } from '@mui/material'
import api from '../../api/api'

const Markets = ({
	currentStations,
	currentMarkets,
	setProgramMarkets,
	setProgramStations
}) => {
	const [stations, setStations] = useState([])
	const [markets, setMarkets] = useState([])
	const [selectedMarkets, setSelectedMarkets] = useState(currentMarkets)
	const [availableStations, setAvailableStations] = useState([])
	const [selectedStations, setSelectedStations] = useState(currentStations)

	console.log(currentStations)
	useEffect(() => {
		getData()
	}, [])

	const getData = async () => {
		try {
			const res_stations = await api.getStations()
			const res_markets = await api.getMarkets()
			setStations(res_stations)
			setMarkets(res_markets)
			setAvailableStations(
				res_stations.filter(item => currentMarkets.includes(item.market))
			)
		} catch (err) {
			console.error(err)
		}
	}
	useEffect(() => {
		setAvailableStations(
			stations.filter(item => selectedMarkets.includes(item.market))
		)
		setProgramMarkets(selectedMarkets)
		setProgramStations(selectedStations)
	}, [selectedMarkets, selectedStations, stations])

	const handleMarket = incomming_market => {
		if (selectedMarkets.includes(incomming_market))
			setSelectedMarkets(
				selectedMarkets.filter(item => item !== incomming_market)
			)
		else setSelectedMarkets([...selectedMarkets, incomming_market])
	}

	const handleStation = (isSelected, incomming_station) => {
		if (isSelected)
			setSelectedStations(
				selectedStations.filter(
					item => item.station !== incomming_station.station
				)
			)
		else {
			setSelectedStations([...selectedStations, incomming_station])
		}
	}

	return (
		<Grid container>
			<CssBaseline />
			<Typography>Markets:</Typography>
			<Grid
				row
				style={{
					height: '5em',
					overflow: 'scroll',
					marginBottom: '1em'
				}}
			>
				<Grid item>
					{markets.map((market, index) => (
						<Button
							key={index}
							onClick={() => handleMarket(market)}
							color={selectedMarkets.includes(market) ? 'secondary' : 'primary'}
							variant={selectedMarkets.includes(market) ? 'contained' : 'text'}
							size='small'
						>
							{market}
						</Button>
					))}
				</Grid>
			</Grid>
			{selectedMarkets.map((selectedMarket, index) => (
				<Grid container key={index}>
					<Typography>{selectedMarket}:</Typography>
					{availableStations.map((availableStation, index1) => {
						const isSelected = selectedStations.some(
							item => item.station === availableStation.station
						)
						return (
							<div key={index1}>
								{availableStation.market === selectedMarket ? (
									<Button
										onClick={() => handleStation(isSelected, availableStation)}
										color={isSelected ? 'secondary' : 'primary'}
										variant={isSelected ? 'contained' : 'text'}
										size='small'
									>
										{availableStation.station}
									</Button>
								) : (
									''
								)}
							</div>
						)
					})}
				</Grid>
			))}
		</Grid>
	)
}

export default Markets
