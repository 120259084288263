import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import {
	Avatar,
	Button,
	CssBaseline,
	FormControl,
	Input,
	InputLabel,
	Paper,
	Typography
} from '@mui/material'
import { LockOutlined } from '@mui/icons-material'
import withStyles from '@mui/styles/withStyles'
import api from '../api/api'
import { emailIsValid } from './emailvalidator'
import { ToastError } from '../components/notifications/Toasts'

const ResetPassword = props => {
	const [email, setEmail] = useState('')
	const navigate = useNavigate()

	const [isValid, setIsValid] = useState(true)

	useEffect(() => {
		setIsValid(!emailIsValid(email))
	}, [email, isValid])

	const submit = async e => {
		e.preventDefault()
		try {
			api.forgot(email.toLowerCase())
			navigate('/')
		} catch (err) {
			ToastError({
				message: 'There was and issue resetting your password—please try again'
			})
			throw err
		}
	}
	const { classes } = props
	return (
		<main className={classes.main}>
			<CssBaseline />
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlined />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Reset Password
				</Typography>
				<form className={classes.form}>
					<FormControl margin='normal' required fullWidth>
						<InputLabel htmlFor='email'>Email Address</InputLabel>
						<Input
							id='email'
							name='email'
							autoComplete='email'
							autoFocus
							onChange={e => setEmail(e.target.value)}
						/>
					</FormControl>
					<Button
						fullWidth
						variant='contained'
						color='primary'
						disabled={isValid}
						className={classes.submit}
						onClick={e => {
							submit(e)
						}}
					>
						Reset Password
					</Button>
				</form>
			</Paper>
		</main>
	)
}

ResetPassword.propTypes = {
	classes: PropTypes.object.isRequired
}
const styles = theme => ({
	main: {
		width: 'auto',
		display: 'flex', // Fix IE 11 issue.
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
		margin: '2em',
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '30%',
		padding: '2em'
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
})

export default withStyles(styles)(ResetPassword)
