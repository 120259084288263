import { useState } from 'react'
import { Grid, Button, Input } from '@mui/material'
import api from '../api/api'

const EditStation = ({ closeDialog, setRefetch, station }) => {
  const [market, setMarket] = useState(station.market)
  const [format, setFormat] = useState(station.format)
  const [owner, setOwner] = useState(station.owner)

  const onSubmit = async () => {
    let stationId = station._id

    try {
      await api.updateStation(stationId, { market, format, owner })
      setRefetch(true)
      closeDialog()
    } catch (err) {
      throw err
    }
  }

  return (
    <Grid container>
      <Grid column>
        <Grid item>
          <Input
            name='market'
            onChange={(e) => setMarket(e.target.value)}
            value={market}
          />
        </Grid>
        <Grid item>
          <Input
            name='format'
            onChange={(e) => setFormat(e.target.value)}
            value={format}
          />
        </Grid>
        <Grid item>
          <Input
            name='owner'
            onChange={(e) => setOwner(e.target.value)}
            value={owner}
          />
        </Grid>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Button type='submit' onClick={() => closeDialog()}>
              {' '}
              Cancel{' '}
            </Button>
          </Grid>
          <Grid item>
            <Button type='submit' onClick={() => onSubmit()}>
              {' '}
              Update{' '}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditStation
