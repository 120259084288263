import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CreateIcon from '@mui/icons-material/Create'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import NewEmployee from './newEmployee'
import NewRep from './newRep'
import NewClient from './newClient'
import withStyles from '@mui/styles/withStyles'
import { UserContext } from '../../../userContext'
import api from '../../../api/api'

import { ToastError } from '../../../components/notifications/Toasts'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const NewUser = props => {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [role, setRole] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [phone, setPhone] = useState('')
	const [company, setCompany] = useState('')
	const [markets, setMarkets] = useState([])
	const [stations, setStations] = useState([])
	const [isAdmin, setIsAdmin] = useState(false)
	const [isMedia, setIsMedia] = useState(false)
	const [isAccounts, setIsAccounts] = useState(false)
	const [isValid, setIsValid] = useState(false)

	useEffect(() => {
		setIsValid(
			![email, firstName, lastName].every(
				item => ![null, undefined, ''].includes(item)
			)
		)
	}, [email, firstName, lastName, isValid])

	const onSubmit = async e => {
		e.preventDefault()
		try {
			const res = await api.createUser({
				email,
				firstName,
				lastName,
				role,
				phone,
				markets,
				stations,
				company,
				isAccounts,
				isAdmin,
				isMedia
			})
			navigate('/sent', { state: { user: res.data } })
		} catch (err) {
			toast.error('Please make sure the user is unique and try again', {
				position: 'bottom-right',
				autoClose: 2500,
				closeOnClick: true,
				pauseOnHover: true
			})
			console.error(err)
		}
	}
	const { classes } = props
	return (
		<UserContext.Consumer>
			{context => (
				<main className={classes.main}>
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar}>
							<CreateIcon />
						</Avatar>
						{context.user.role === 'tmpg' ? (
							<Typography component='h1' variant='h5'>
								Select a user type
							</Typography>
						) : (
							<Typography component='h1' variant='h5'>
								Add a user
							</Typography>
						)}
						<form className={classes.form}>
							<FormControl margin='normal' required fullWidth>
								<Select
									name='role'
									value={role}
									onChange={e => setRole(e.target.value)}
								>
									<MenuItem value={'tmpg'}>Employee</MenuItem>
									<MenuItem value={'client'}>Client</MenuItem>
									<MenuItem value={'rep'}>Station Rep</MenuItem>
								</Select>
							</FormControl>
							{role === 'tmpg' ? (
								<div>
									<NewEmployee
										vals={{
											phone,
											markets,
											stations,
											firstName,
											lastName,
											email,
											company,
											isAdmin,
											isMedia,
											isAccounts
										}}
										setFirstName={setFirstName}
										setLastName={setLastName}
										setMarkets={setMarkets}
										setStations={setStations}
										setEmail={setEmail}
										setCompany={setCompany}
										setPhone={setPhone}
										setIsAccounts={setIsAccounts}
										setIsAdmin={setIsAdmin}
										setIsMedia={setIsMedia}
									/>
								</div>
							) : role === 'rep' ? (
								<div>
									<NewRep
										vals={{
											phone,
											markets,
											stations,
											firstName,
											lastName,
											email,
											company
										}}
										setFirstName={setFirstName}
										setLastName={setLastName}
										setMarkets={setMarkets}
										setStations={setStations}
										setEmail={setEmail}
										setCompany={setCompany}
										setPhone={setPhone}
									/>
								</div>
							) : role === 'client' ? (
								<div>
									<NewClient
										vals={{
											phone,
											markets,
											stations,
											firstName,
											lastName,
											email,
											role,
											company
										}}
										setFirstName={setFirstName}
										setLastName={setLastName}
										setMarkets={setMarkets}
										setStations={setStations}
										setEmail={setEmail}
										setCompany={setCompany}
										setPhone={setPhone}
									/>
								</div>
							) : (
								<div />
							)}
							<Button
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								className={classes.submit}
								disabled={isValid}
								onClick={onSubmit}
							>
								Send {firstName} an Invite
							</Button>
						</form>
					</Paper>
				</main>
			)}
		</UserContext.Consumer>
	)
}

NewUser.propTypes = {
	classes: PropTypes.object.isRequired
}
const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'row',
		width: 'auto',
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		justifyContent: 'center',
		alignContent: 'center',
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			width: 600,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		width: '350px',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		marginTop: theme.spacing(3)
	}
})
export default withStyles(styles)(NewUser)
