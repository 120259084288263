import { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import MaterialTable from 'material-table'
import { Grid } from '@mui/material'

import { PlayArrow, CloudDownload, Stop } from '@mui/icons-material'
import ReplaceSpot from './replaceSpot'
import SpotComments from './spotComments'

const styles = theme => ({
	commentsActions: {
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(3, 3),
		padding: theme.spacing(2, 2)
	}
})

const audio = new Audio()

const RepSpotTable = ({ spots }) => {
	const [open, setOpen] = useState(false)

	const playSpot = data => {
		audio.src = data.attachment.url
		audio.play()
	}
	const stopSpot = data => {
		audio.src = data.attachment.url
		audio.pause()
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Grid item sm={12} style={{ marginTop: 20 }}>
			<MaterialTable
				title={`Spots`}
				data={spots}
				columns={[
					{ title: 'Type', field: 'type' },
					{ title: 'Station', field: 'station' },
					{ title: 'Name', field: 'name' },
					{ title: 'Status', field: 'status' },
					{
						title: 'Upload date',
						field: 'attachment.createdAt',
						type: 'date'
					}
				]}
				actions={[
					{
						icon: CloudDownload,
						tooltip: 'Download',
						onClick: (event, rowData) => {
							event.preventDefault()
							window.open(rowData.attachment.url, 'download')
						}
					},
					{
						icon: PlayArrow,
						tooltip: 'Play',
						onClick: (event, rowData) => {
							playSpot(rowData)
						}
					},
					{
						icon: Stop,
						tooltip: 'Stop',
						onClick: (event, rowData) => {
							stopSpot(rowData)
						}
					}
				]}
				detailPanel={[
					{
						render: rowData => <SpotComments comments={rowData.comments} />
					}
				]}
				options={{
					grouping: true,
					exportButton: true,
					filtering: true,
					actionsColumnIndex: -1,
					pageSize: 5
				}}
			/>
			<ReplaceSpot
				open={open}
				setOpen={setOpen}
				handleOpen={handleOpen}
				handleClose={handleClose}
			/>
		</Grid>
	)
}
export default withStyles(styles)(RepSpotTable)
