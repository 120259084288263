import { Component } from 'react'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'

class BasicContact extends Component {
	render() {
		return (
			<div>
				<FormControl margin='normal' required fullWidth>
					<InputLabel htmlFor='firstName'> First Name </InputLabel>
					<Input
						autoFocus
						name='firstName'
						type='text'
						id='first-name'
						required={true}
						value={this.props.vals.firstName}
						onChange={e => this.props.setFirstName(e.target.value)}
					/>
				</FormControl>
				<FormControl margin='normal' required fullWidth>
					<InputLabel htmlFor='lastName'> Last Name </InputLabel>
					<Input
						name='lastName'
						type='text'
						id='last-name'
						required={true}
						value={this.props.vals.lastName}
						onChange={e => this.props.setLastName(e.target.value)}
					/>
				</FormControl>
				<FormControl margin='normal' required fullWidth>
					<InputLabel htmlFor='email'> Email Address </InputLabel>
					<Input
						id='email'
						name='email'
						required={true}
						value={this.props.vals.email}
						onChange={e => this.props.setEmail(e.target.value)}
					/>
				</FormControl>
			</div>
		)
	}
}

export default BasicContact
