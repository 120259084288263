import axios from 'axios'

const api = {
	getPrograms: async () => {
		try {
			const response = await axios.get(`/api/programs`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	getProgram: async id => {
		try {
			const response = await axios.get(`/api/programs/${id}`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	createProgram: async programAttributes => {
		try {
			const response = await axios.post(`/api/programs`, programAttributes)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	updateProgram: async (id, programAttributes) => {
		try {
			const response = await axios.put(`/api/programs/${id}`, programAttributes)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	updateProgramExtras: async (id, programAttributes) => {
		try {
			const response = await axios.post(
				`/api/programs/${id}/uploads`,
				programAttributes
			)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	updateProgramSpotDocs: async (id, spotType, programAttributes) => {
		try {
			const response = await axios.put(
				`/api/programs/${id}/doc/uploads/${spotType}`,
				programAttributes
			)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	getProgramsByClient: async clientId => {
		try {
			const response = await axios.get(`/api/programs?client=${clientId}`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	getProgramsByRepAndStation: async (repId, stationId) => {
		try {
			const response = await axios.get(`/api/programs?station=${stationId}`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	archiveProgram: async id => {
		try {
			const response = await axios.put(`/api/programs/${id}/archive`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	archiveAttachment: async (programId, attachmentId, spotType) => {
		try {
			const response = await axios.put(
				`/api/programs/archiveAttachment/${programId}/${attachmentId}/${spotType}`
			)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	duplicateProgram: async id => {
		try {
			const res = await axios.post(`/api/programs/${id}/duplicate`)
			return res.data
		} catch ({ response }) {
			return response.data
		}
	}
}

export default api
