import axios from 'axios'

const api = {
  createStation: async (stationAttributes) => {
    try {
      const response = await axios.post(`/api/stations`, stationAttributes)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  updateStation: async (station, stationAttributes) => {
    try {
      const response = await axios.put(
        `/api/stations/${station}/update`,
        stationAttributes
      )
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  getStations: async () => {
    try {
      const response = await axios.get(`/api/stations`)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  getStation: async (station) => {
    try {
      const response = await axios.get(`/api/stations/${station}`)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  getMarkets: async () => {
    try {
      const response = await axios.get(`/api/stations/markets`)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
}
export default api
