import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import {
	Button,
	FormControl,
	FormGroup,
	FormControlLabel,
	Switch,
	Checkbox,
	Input,
	Select,
	MenuItem,
	InputLabel,
	FormLabel,
	Paper,
	Typography,
	CssBaseline,
	Avatar,
	Grid
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import DropzoneAreaExample from '../dropzone'
import CreateIcon from '@mui/icons-material/Create'
import api from '../../api/api'
import Markets from './markets'
import SpotRequirements from '../spots/spotRequirements'
import SelectDates from '../selectDatesOfRotation'
import { includes, pull } from 'lodash'
import dayjs from 'dayjs'
import { ToastError } from '../notifications/Toasts'

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
			width: 800,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		marginTop: theme.spacing(3)
	},
	noLabel: {
		marginTop: theme.spacing(3)
	}
})

let clients = []

const NewProgram = props => {
	const navigate = useNavigate()
	const [product, setProduct] = useState('')
	const [name, setName] = useState('')
	const [status, setStatus] = useState('draft')
	const [aircheck, setAircheck] = useState(true)
	const [clientReview, setClientReview] = useState(false)
	const [variesByMarket, setVariesByMarket] = useState(false)
	const [variesByStation, setVariesByStation] = useState(false)
	const [issuingBrand, setIssuingBrand] = useState('')
	const [client, setClient] = useState({
		name: '',
		id: ''
	})
	const [markets, setMarkets] = useState([])
	const [selectedMarkets, setSelectedMarkets] = useState([])
	const [stations, setStations] = useState([])
	const [selectedStations, setSelectedStations] = useState([])
	const [rotationDates, setRotationDates] = useState({})
	const [errorMessages, setErrorMessages] = useState([])
	const [attachments, setAttachments] = useState({})
	const [spots, setSpots] = useState({
		':15 DJ Endorsements': {
			requiredSpot: false
		},
		':30 DJ Endorsements': {
			requiredSpot: false
		},
		':60 DJ Endorsements': {
			requiredSpot: false
		},
		':15 Brand Spot': {
			requiredSpot: false
		},
		':30 Brand Spot': {
			requiredSpot: false
		},
		':60 Brand Spot': {
			requiredSpot: false
		},
		'Brand Centric Promos': {
			requiredSpot: false
		},
		'Sweepstakes Promos': {
			requiredSpot: false
		},
		'Appearance Promos': {
			requiredSpot: false
		},
		'Call-ins': {
			requiredSpot: false
		},
		'Sponsorship / Brand Mention': {
			requiredSpot: false
		},
		'N_W_TSpot': {
			requiredSpot: false
		},
		'Vignettes': {
			requiredSpot: false
		},
		'Interviews': {
			requiredSpot: false
		},
		'Custom': {
			requiredSpot: false
		}
	})
	const [selectedSpots, setSelectedSpots] = useState([])
	useEffect(() => {
		getClients()
	}, [])
	const getClients = async () => {
		try {
			const data = await api.allClients()
			for (let client in data) {
				clients.push({ name: data[client].company, id: data[client]._id })
			}
		} catch (err) {
			throw err
		}
	}

	const handleToggle = (name, event) => {
		setSpots({ ...spots, [name]: { requiredSpot: event.target.checked } })
		const tempSpots = selectedSpots
		!includes(selectedSpots, name) && event.target.checked === true
			? tempSpots.push(name)
			: pull(tempSpots, name)
		setSelectedSpots(selectedSpots)
	}

	// for file inputs
	const handleFileInputChange = (name, files) => {
		setAttachments(prevState => {
			let { attachments } = prevState
			attachments[name] = files[0]
			return attachments
		})
	}

	// for dropzones
	const handleDropzoneChange = files => {
		setAttachments(prevState => {
			let { attachments } = prevState
			attachments = files
			return attachments
		})
	}

	const handleObjChange = (event, spotType, field) => {
		let temp = spots
		if (field === 'dueDate')
			temp[spotType][field] = dayjs(event).format('MM-DD-YYYY')
		else temp[spotType][field] = event.target.value
		setSpots(temp)
	}

	const onSubmit = async () => {
		const formData = new FormData()
		formData.append('name', name)
		formData.append('product', product)
		formData.append('client', JSON.stringify(client))
		formData.append('aircheck', aircheck)
		formData.append('status', status)
		formData.append('issuingBrand', issuingBrand)
		formData.append('clientReview', clientReview)
		formData.append('variesByMarket', variesByMarket)
		formData.append('variesByStation', variesByStation)
		formData.append('rotationDates', JSON.stringify(rotationDates))
		formData.append('markets', JSON.stringify(markets))
		formData.append('stations', JSON.stringify(stations))
		formData.append(
			'DJ_15_Endorsements',
			JSON.stringify(spots[':15 DJ Endorsements'])
		)
		formData.append(
			'DJ_30_Endorsements',
			JSON.stringify(spots[':30 DJ Endorsements'])
		)
		formData.append(
			'DJ_60_Endorsements',
			JSON.stringify(spots[':60 DJ Endorsements'])
		)
		formData.append('Brand_15_Spot', JSON.stringify(spots[':15 Brand Spot']))
		formData.append('Brand_30_Spot', JSON.stringify(spots[':30 Brand Spot']))
		formData.append('Brand_60_Spot', JSON.stringify(spots[':60 Brand Spot']))
		formData.append(
			'BrandCentricPromos',
			JSON.stringify(spots['Brand Centric Promos'])
		)

		formData.append(
			'SweepstakesPromos',
			JSON.stringify(spots['Sweepstakes Promos'])
		)

		formData.append(
			'AppearancePromos',
			JSON.stringify(spots['Appearance Promos'])
		)
		formData.append('Callins', JSON.stringify(spots['Call-ins']))
		formData.append(
			'SponsorshipBrandMention',
			JSON.stringify(spots['Sponsorship / Brand Mention'])
		)
		formData.append('N_W_TSpot', JSON.stringify(spots['N_W_TSpot']))
		formData.append('Vignettes', JSON.stringify(spots.Vignettes))
		formData.append('Interviews', JSON.stringify(spots.Interviews))
		formData.append('Custom', JSON.stringify(spots.Custom))

		attachments.forEach(file => {
			formData.append('extras', file)
		})

		try {
			const res = await api.createProgram(formData)
			navigate(`/view/program/${res._id}`)
		} catch (err) {
			setErrorMessages(err)
			ToastError({
				message:
					'There was an issue creating the program—please review the program for errors'
			})
		}
	}

	const arr = Array.apply(null, { length: 25 }).map(Number.call, Number)

	const spotArray = [
		':15 DJ Endorsements',
		':30 DJ Endorsements',
		':60 DJ Endorsements',
		':15 Brand Spot',
		':30 Brand Spot',
		':60 Brand Spot',
		'Brand Centric Promos',
		'Sweepstakes Promos',
		'Appearance Promos',
		'Call-ins',
		'Sponsorship / Brand Mention',
		'N_W_TSpot',
		'Vignettes',
		'Interviews',
		'Custom'
	]
	const { classes } = props
	return (
		<main className={classes.main}>
			<CssBaseline />
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<CreateIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Create new program or aircheck
				</Typography>
				<form className={classes.form}>
					{errorMessages.length > 0 &&
						errorMessages.map((message, i) => <h1 key={i}>{message}</h1>)}
					<Grid container>
						<Grid container direction='row'>
							<FormGroup row>
								<FormControlLabel
									control={
										<Switch
											checked={aircheck}
											onChange={e => setAircheck(e.target.checked)}
											value='aircheck'
										/>
									}
									label='Aircheck'
								/>
							</FormGroup>
						</Grid>
						<Grid container direction='row' spacing={4} style={{ margin: 20 }}>
							<Grid item sm={3}>
								<FormControl margin='normal' required fullWidth>
									<InputLabel htmlFor='program'> Product </InputLabel>
									<Input
										autoFocus
										name='product'
										type='text'
										id='product'
										value={product}
										onChange={e => setProduct(e.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid item sm={3}>
								<FormControl margin='normal' required fullWidth>
									<InputLabel htmlFor='program'> Program Name </InputLabel>
									<Input
										name='name'
										type='text'
										id='program'
										value={name}
										onChange={e => setName(e.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid item sm={3}>
								<FormControl margin='normal' required fullWidth>
									<InputLabel htmlFor='program'> Issuing Brand </InputLabel>
									<Input
										name='issuingBrand'
										type='text'
										value={issuingBrand}
										onChange={e => setIssuingBrand(e.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid item sm={3}>
								<FormControl
									margin='normal'
									required
									fullWidth
									variant='standard'
								>
									<InputLabel htmlFor='client'> Client </InputLabel>
									<Select
										value={client}
										onChange={e => setClient(e.target.value)}
									>
										{clients.map(client => (
											<MenuItem key={client.name} value={client}>
												{client.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container direction='row'>
							<Markets
								markets={markets}
								currentStations={selectedStations}
								currentMarkets={selectedMarkets}
								setProgramMarkets={setMarkets}
								setProgramStations={setStations}
							/>
						</Grid>
						<SelectDates
							variesByStation={variesByStation}
							setVariesByStation={setVariesByStation}
							variesByMarket={variesByMarket}
							setVariesByMarket={setVariesByMarket}
							setRotationDates={setRotationDates}
							vals={{ markets, stations, rotationDates }}
						/>

						<Grid container direction='row'>
							<Grid item sm={12}>
								<Typography style={{ marginBotton: '1em' }}>Spots:</Typography>
							</Grid>
							<FormGroup row>
								<FormControlLabel
									control={
										<Switch
											checked={clientReview}
											onChange={e => setClientReview(e.target.checked)}
											value='clientReview'
										/>
									}
									label='Client must approve all spots'
								/>
							</FormGroup>
						</Grid>
						<Grid container direction='row'>
							<FormControl component='fieldset' className={classes.formControl}>
								<FormLabel component='legend'>Select Type(s)</FormLabel>
								<FormGroup row>
									{spotArray.map((spotType, index) => (
										<Grid item sm={4}>
											<FormControlLabel
												style={{ marginLeft: 10 }}
												size='small'
												control={
													<Checkbox
														checked={spots[spotType].requiredSpot}
														onChange={e => handleToggle(spotType, e)}
														value={spots[spotType].requiredSpot}
													/>
												}
												label={spotType}
											/>
										</Grid>
									))}
								</FormGroup>
							</FormControl>
						</Grid>
						{selectedSpots.map((spotType, index) => (
							<Grid container direction='row' key={index}>
								<SpotRequirements
									props={props}
									attachments={attachments}
									vals={spots[spotType]}
									handleObjChange={handleObjChange}
									handleFileInputChange={handleFileInputChange}
									spotType={spotType}
									array={arr}
								/>
							</Grid>
						))}
						<Grid container direction='row' style={{ marginTop: 20 }}>
							<Grid item>
								<InputLabel style={{ marginBotton: 20 }}>
									Added Value Assets
								</InputLabel>
								<FormControl>
									<DropzoneAreaExample onChange={handleDropzoneChange} />
								</FormControl>
							</Grid>
							<Grid item></Grid>
						</Grid>
					</Grid>
					<Grid container direction='row' justifyContent='flex-end' spacing={2}>
						<Grid item>
							<Button
								variant='contained'
								color='secondary'
								className={classes.submit}
								onClick={() => {
									setStatus('draft')
									onSubmit()
								}}
							>
								Save Draft
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								className={classes.submit}
								onClick={() => {
									setStatus('published')
									onSubmit()
								}}
							>
								Publish
							</Button>
						</Grid>
					</Grid>
				</form>
			</Paper>
		</main>
	)
}

NewProgram.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NewProgram)
