import { useState } from 'react'
import {
  Typography,
  Grid,
  Button,
  Paper,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import EditUser from './editUser'

const Profile = ({ user, setUser }) => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [value, setValue] = useState('')

  const edit = (n, val) => {
    setName(n)
    setValue(val)
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const deleteStation = (station) => {
    //TODO: add delete station here
  }

  return (
    <div>
      {user && (
        <Grid container justifyContent='center' style={{ marginTop: 20 }}>
          <CssBaseline />
          <Grid item sm={8}>
            <Typography style={{ margin: 20 }}>Profile</Typography>
            <Paper style={{ padding: 20, marginTop: 20 }}>
              <Grid container>
                {user.role === 'client' && (
                  <Grid container direction='row'>
                    <Grid item sm={2}>
                      <Typography align='left'>Company:</Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <Typography align='left'>
                        {user.company ? user.company : ''}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Button onClick={() => edit('company', user.company)}>
                        edit company
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Grid container direction='row'>
                  <Grid item sm={2}>
                    <Typography align='left'>Name:</Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Typography align='left'>
                      {user.firstName + ' ' + user.lastName}
                    </Typography>
                  </Grid>
                  <Grid item sm={2}>
                    <Button onClick={() => edit('firstName', user.firstName)}>
                      edit first
                    </Button>
                    <Button onClick={() => edit('lastName', user.lastName)}>
                      edit last
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction='row'>
                  <Grid item sm={2}>
                    <Typography align='left'>Email:</Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Typography align='left'>{user.email}</Typography>
                  </Grid>
                  <Grid item sm={2}>
                    <Button onClick={() => edit('email', user.email)}>
                      edit email
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction='row'>
                  <Grid item sm={2}>
                    <Typography align='left'>Phone:</Typography>
                  </Grid>
                  {user.role !== 'client' && (
                    <>
                      <Grid item sm={8}>
                        <Typography align='left'>
                          {user.phone ? user.phone : ''}
                        </Typography>
                      </Grid>
                      <Grid item sm={2}>
                        <Button onClick={() => edit('phone', user.phone)}>
                          edit phone
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container direction='row'>
                  <Grid item sm={2}>
                    <Typography align='left'>Notifications:</Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Typography align='left'>
                      {user
                        ? user.notification
                        : 'Please select a perfered style of notification'}
                    </Typography>
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      disabled={true}
                      onClick={() => edit('notification', user.notification)}
                    >
                      edit notifications
                    </Button>
                  </Grid>
                </Grid>
                {user && user.role === 'rep' && (
                  <Grid container direction='row'>
                    <Grid item>
                      <Typography align='left'>Station(s):</Typography>
                    </Grid>
                    {user.stations.map((station) => (
                      <Grid item style={{ paddingLeft: '.5em' }}>
                        <Typography>{station.station}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {user && user.role === 'client' && (
                  <Grid container>
                    {!!user.subClients &&
                      user.subClients.map((subUser) => (
                        <Grid container direction='row'>
                          <Grid container direction='row'>
                            <Grid item>Name:</Grid>
                            <Grid item>{subUser.name}</Grid>
                            <Grid item>Email:</Grid>
                            <Grid item>{subUser.email}</Grid>
                          </Grid>
                          <Grid container direction='row'>
                            <Grid item>Program:</Grid>
                            <Grid item>{subUser.program.name}</Grid>
                            <Grid item>
                              <Button onClick={() => edit('subUser')}></Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Dialog open={open} onClose={() => closeDialog()}>
            <DialogTitle>Edit {name}</DialogTitle>
            <DialogContent>
              <EditUser
                user={user}
                setUser={setUser}
                field={name}
                userId={user._id}
                currentValue={value}
                closeDialog={closeDialog}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      )}
    </div>
  )
}

export default Profile
