import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import { Pageview } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { CssBaseline, Grid, FormControl, MenuItem, Select } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import api from '../api/api'

const styles = theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
})

const RepDashboard = props => {
	const { user } = props
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [station, setStation] = useState('')

	const callAPI = async val => {
		try {
			const res = await api.getProgramsByRepAndStation(user._id, val)
			setData(res)
		} catch (err) {
			throw err
		}
	}

	const handleChange = e => {
		if (e.target.value !== '') {
			setStation(e.target.value)
			callAPI(e.target.value)
		}
	}
	const { classes } = props
	return (
		<Grid style={{ margin: 20 }}>
			<FormControl className={classes.formControl}>
				<Select
					style={{ minWidth: 250, maxWidth: 300 }}
					name='station'
					id='station'
					value={station}
					onChange={handleChange}
					displayEmpty
					className={classes.selectEmpty}
				>
					<MenuItem value=''>Select a Station to view</MenuItem>
					{user &&
						user.stations.map(station => (
							<MenuItem key={station} value={station.station}>
								{station.station}
							</MenuItem>
						))}
				</Select>
			</FormControl>
			<CssBaseline />
			<MaterialTable
				title={
					station
						? `Airchecks for station: ${station}`
						: 'Select a station to view airchecks'
				}
				columns={[
					{
						title: 'Program',
						field: 'name',
						render: rowData => (
							<Link
								to={`/view/program/${rowData._id}`}
								style={{ textDecoration: 'none', color: 'black' }}
							>
								{rowData.name}
							</Link>
						)
					},
					{
						title: 'Start Date',
						field: 'rotationDates[0].startDate',
						type: 'numeric'
					},
					{
						title: 'End Date',
						field: 'rotationDates[0].endDate',
						type: 'numeric'
					},
					{ title: 'Status', field: 'status' }
				]}
				data={data}
				actions={[
					{
						icon: Pageview,
						tooltip: 'View Program',
						onClick: (event, rowData) =>
							navigate(`/view/program/${rowData._id}/station/${station}`)
					}
				]}
				options={{
					actionsColumnIndex: -1
				}}
			/>
		</Grid>
	)
}

RepDashboard.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RepDashboard)
