import { Component } from 'react'
import { Typography, Grid } from '@mui/material'
import moment from 'moment'

class DatesOfRotation extends Component {
  state = {
    market: '',
    station: '',
    byStation: false,
    byMarket: false,
    ranges: [],
  }
  componentDidMount() {
    if (this.props.station) {
      const currentStation = this.props.station
      const { program } = this.props

      program.stations.forEach((station) => {
        if (station.station === currentStation) {
          this.setState({ market: station.market })
        }
      })

      let rangesByStations = []
      let rangesByMarket = []
      let rangesByProgram = []

      program.rotationDates.forEach((range) => {
        if (Object.values(range).includes(currentStation)) {
          this.setState({ byStation: true })
          rangesByStations.push(range)
        } else if (Object.values(range).includes(this.state.market)) {
          this.setState({ byMarket: true })
          rangesByMarket.push(range)
        } else if (
          !range.hasOwnProperty('market') &&
          !range.hasOwnProperty('station')
        ) {
          rangesByProgram.push(range)
        }
      })
      if (this.state.byStation) {
        this.setState({ ranges: rangesByStations })
      } else if (this.statebyMarket) {
        this.setState({ ranges: rangesByMarket })
      } else {
        this.setState({ ranges: rangesByProgram })
      }
    } else {
      this.setState({ ranges: this.props.program.rotationDates }, () => {})
    }
  }

  render() {
    return (
      <Grid container direction='row' style={{ marginBottom: '1em' }}>
        <Typography>Flight Dates</Typography>
        {this.state.ranges &&
          this.state.ranges.map((range) => (
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item>
                <Typography>
                  Start: {moment(range.startDate).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  End: {moment(range.endDate).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {range.market ? range.market : ''}
                  {range.station ? range.station : ''}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    )
  }
}
export default DatesOfRotation
