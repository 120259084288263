import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { Avatar, CssBaseline, Paper, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import withStyles from '@mui/styles/withStyles'

const Sent = props => {
	const { state } = useLocation()
	const { classes } = props
	const { name, email, token } = state.user

	return (
		<main className={classes.main}>
			<CssBaseline />
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<SendIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Invitation sent to {name.toUpperCase()} at {email}
				</Typography>
				<Typography component='h3' variant='caption'>
					Unique invitation link for {name}'s manual sign up:
				</Typography>
				<Typography component='h3' variant='caption'>
					<noBr> http://romeo.tmpg.com/verify/{token.token}</noBr>
				</Typography>
			</Paper>
		</main>
	)
}

Sent.propTypes = {
	classes: PropTypes.object.isRequired
}

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'flex', // Fix IE 11 issue.
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
		margin: '2em',
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			width: 600,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '45%',
		padding: '2em',
		textAlign: 'center'
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
})

export default withStyles(styles)(Sent)
