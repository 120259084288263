import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Grid,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import CreateIcon from '@mui/icons-material/Create'
import HomeIcon from '@mui/icons-material/Home'
import ExitToApp from '@mui/icons-material/ExitToApp'
import { LoggedIn, LoggedOut, logout } from '../auth/AuthService'
import { UserContext } from '../userContext'

const MenuAppBar = ({ user, setUser }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEL] = useState(null)

  const handleMenu = (event) => {
    setAnchorEL(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEL(null)
  }

  const open = Boolean(anchorEl)
  return (
    <Grid container direction='row'>
      <AppBar position='static'>
        <Toolbar>
          <Grid container justifyContent='flex-start'>
            <LoggedIn>
              <IconButton
                color='inherit'
                onClick={() => navigate('/')}
                size='large'
              >
                <HomeIcon />
              </IconButton>
            </LoggedIn>

            <LoggedOut>
              <Typography variant='h6' color='inherit'>
                TMPG's ROMEO
              </Typography>
            </LoggedOut>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <LoggedIn>
              <div>
                {user && user.role === 'tmpg' ? (
                  <span>
                    <IconButton
                      aria-owns={open ? 'menu-appbar' : undefined}
                      aria-haspopup='true'
                      onClick={(e) => handleMenu(e)}
                      color='inherit'
                      size='large'
                    >
                      <CreateIcon />
                    </IconButton>
                    <Menu
                      id='menu-appbar'
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={(e) => handleClose(e)}
                    >
                      <MenuItem onClick={(e) =>{ 
                        navigate('/new')
                        handleClose(e)
                        }}>
                        <span >
                          Create User Account
                        </span>
                      </MenuItem>
                      <MenuItem onClick={(e) => {
                        navigate('/program')
                        handleClose(e)}}>
                        <span>
                          Create Program/Aircheck
                        </span>
                      </MenuItem>
                      <MenuItem onClick={(e) => {
                        navigate('/view/stations')
                        handleClose(e)}}>
                        <span>
                          View/Edit Stations List
                        </span>
                      </MenuItem>
                      <MenuItem onClick={(e) =>{
                        navigate('/view/users')
                        handleClose(e)}}>
                        <span>
                          View/Edit All Users
                        </span>
                      </MenuItem>
                    </Menu>
                  </span>
                ) : (
                  ''
                )}
                <span>
                  <IconButton
                    color='inherit'
                    onClick={() => navigate('/profile')}
                    size='large'
                  >
                    <AccountCircle />
                  </IconButton>
                </span>
                <IconButton
                  color='inherit'
                  onClick={() => {
                    logout()
                    navigate('/')
                    setUser(null)
                  }}
                  size='large'
                >
                  <ExitToApp />
                </IconButton>
              </div>
            </LoggedIn>
          </Grid>
        </Toolbar>
      </AppBar>
    </Grid>
  )
}
export default MenuAppBar
