import axios from 'axios'
import {
	isLoggedIn,
	setLoginTokens,
	saveUser,
	logout
} from '../auth/AuthService'
import SPOTS_API from './spots'
import PROGRAMS_API from './programs'
import STATIONS_API from './stations'
import { Error } from '@mui/icons-material'

let headers = {
	'Content-Type': 'application/json'
}

const api = {
	Login: async function (body) {
		try {
			const res = await axios.post(`/api/user/authenticate`, {
				email: body.email.toLowerCase(),
				password: body.password,
				headers
			})
			setLoginTokens(res.data.token)
			const user = res.data.user
			saveUser(user)
			return user
		} catch (err) {
			throw err
		}
	},
	createUser: async function (user) {
		try {
			const res = await axios.post('/api/user/register', user)
			return res
		} catch (err) {
			const error = new Error(Error)
			throw error
		}
	},
	allClients: async function () {
		const res = await axios.get('/api/users/clients')
		if (res.status !== 200) {
			const err = new Error(res.error)
			throw err
		} else {
			return res.data
		}
	},
	Verify: async function (body, invitationToken, navigate) {
		try {
			const res = await axios.post(`/api/user/verify`, {
				password: body.password,
				confirmPassword: body.confirmPassword,
				markets: body.markets ? body.markets : '',
				stations: body.stations ? body.stations : '',
				invitationToken,
				headers
			})

			if (res.status === 200) {
				navigate(`/`)
			} else {
				const error = new Error(res.error)
				throw error
			}
		} catch (err) {
			navigate('/noToken')
		}
	},

	updateUser: async function () {
		if (!isLoggedIn()) {
			return null
		}
		//fix the error handler to account for errors
		try {
			const res = await axios.get(`/api/user`)
			localStorage.setItem('tmpg-user', JSON.stringify(res.data))
			return res.data
		} catch (err) {
			logout()
		}
	},
	forgot: async email => {
		console.log(`in the api forgot ${email}`)
		try {
			const response = await axios.post('/api/user/forgot', { email: email })
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	getUsers: async () => {
		try {
			const response = await axios.get('/api/users')
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	editUser: async (id, userAttributes) => {
		try {
			const response = await axios.put(`/api/user/${id}/edit`, userAttributes)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	archiveUser: async id => {
		try {
			const response = await axios.put(`/api/user/${id}/archive`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	reactivateUser: async id => {
		try {
			const response = await axios.put(`/api/user/${id}/reactivate`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	editRep: async (id, userAttributes) => {
		try {
			const response = await axios.put(`/api/user/${id}/edit`, userAttributes)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	createSubUser: async subUserAttributes => {
		try {
			const response = await axios.post(`/api/subUser`, subUserAttributes)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	getSubUsers: async clientId => {
		try {
			const response = await axios.get(`/api/subUsers/${clientId}`)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	updateSubUser: async (userId, programs) => {
		try {
			const response = await axios.put(
				`/api/subUsers/${userId}/add/${programs}`
			)
			return response.data
		} catch ({ response }) {
			return response.data
		}
	},
	...PROGRAMS_API,
	...SPOTS_API,
	...STATIONS_API
}

export default api
