import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import api from '../../api/api'
import { CssBaseline, Grid, Typography, IconButton } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Delete } from '@mui/icons-material'
import ReactPlayer from 'react-player'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { isEmpty } from 'lodash'
import { UserContext } from '../../userContext'
import { ToastError } from '../notifications/Toasts'

const styles = theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		textAlign: 'left'
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	}
})

const ExtraContent = ({ extras, classes, programId }) => {
	let [videos, setVideos] = useState([])
	let [images, setImages] = useState([])
	let [documents, setDocuments] = useState([])
	let [other, setOther] = useState([])

	useEffect(() => {
		const docs = ['doc', 'docx', 'pdf']
		const imgs = ['jpg', 'jpeg', 'png', 'gif', 'tiff']
		const movs = ['mp4', 'mpeg', 'mov', 'mpg', 'm4v', 'm4p']
		extras.forEach(extra => {
			if (extra.attachment.active) {
				if (
					docs.includes(
						extra.attachment.filename.split('.').pop().toLowerCase()
					)
				) {
					let tempDoc = documents
					tempDoc.push(extra)
					setDocuments(tempDoc)
				} else if (
					imgs.includes(
						extra.attachment.filename.split('.').pop().toLowerCase()
					)
				) {
					let tempImg = images
					tempImg.push(extra.attachment)
					setImages(tempImg)
				} else if (
					movs.includes(
						extra.attachment.filename.split('.').pop().toLowerCase()
					)
				) {
					setVideos([
						...videos,
						{
							url: extra.attachment.url,
							id: extra.attachment._id
						}
					])
				} else {
					let tempOther = other
					tempOther.push(extra)
					setOther(tempOther)
				}
			}
		})
	}, [])

	const archiveAttachment = async (id, type) => {
		try {
			await api.archiveAttachment(programId, id, 'extras')
			switch (type) {
				case 'document':
					let tempDocs = documents
					setDocuments(tempDocs.filter(doc => doc.attachment._id !== id))
					break
				case 'video':
					let tempVideo = videos
					setVideos(tempVideo.filter(vid => vid.id !== id))
					break
				case 'image':
					let tempImages = images
					setImages(tempImages.filter(img => img._id !== id))
					break
				case 'other':
					let tempOther = other
					setOther(tempOther.filter(img => img._id !== id))
					break
				default:
					break
			}
		} catch (err) {
			console.log(err)
			ToastError({
				message: 'Error uploading please try again'
			})
		}
	}
	return (
		<UserContext.Consumer>
			{({ user }) => (
				<Grid id='extras' container direction='row'>
					<CssBaseline />
					<Typography
						className={classes.heading}
						style={{ marginBottom: '1em' }}
					>
						Added Value Assets:
					</Typography>
					<Grid container>
						{!isEmpty(videos) && (
							<Grid container direction='row' justifyContent='space-around'>
								<Grid container direction='row'>
									<Typography
										className={classes.heading}
										style={{ marginBottom: '1em' }}
									>
										Videos
									</Typography>
								</Grid>
								{videos.map(vid => (
									<Grid
										container
										direction='row'
										justifyContent='space-around'
										key={vid.url}
									>
										<ReactPlayer
											controls={true}
											playing={false}
											url={vid.url}
										/>
										{user && user.role === 'tmpg' && (
											<IconButton
												aria-label='delete'
												onClick={() => archiveAttachment(vid.id, 'video')}
												size='large'
											>
												<Delete />
											</IconButton>
										)}
									</Grid>
								))}
							</Grid>
						)}
					</Grid>
					<Grid container>
						{!isEmpty(images) && (
							<Grid container direction='row' justifyContent='space-around'>
								<Grid container direction='row'>
									<Typography
										className={classes.heading}
										style={{ marginBottom: '1em' }}
									>
										Images
									</Typography>
								</Grid>
								<Carousel>
									{images.map((img, i) => (
										<div key={i}>
											<img alt={img.filename} src={img.url} />
											<div style={{ margin: '1.2em' }}>
												{user && user.role === 'tmpg' && (
													<IconButton
														aria-label='delete'
														onClick={() => archiveAttachment(img._id, 'image')}
														size='large'
													>
														<Delete />
													</IconButton>
												)}
											</div>
										</div>
									))}
								</Carousel>
							</Grid>
						)}
					</Grid>
					<Grid container>
						{!isEmpty(documents) && (
							<Grid container direction='row' justifyContent='space-around'>
								<Grid container direction='row'>
									<Typography
										className={classes.heading}
										style={{ marginBottom: '1em' }}
									>
										Documents
									</Typography>
								</Grid>
								{documents.map((tempDoc, index) => (
									<Grid key={'extras' + index} item md={12}>
										<iframe
											title={tempDoc.attachment.filename}
											src={`https://docs.google.com/gview?url=${tempDoc.attachment.url}&embedded=true`}
										/>
										{user && user.role === 'tmpg' && (
											<IconButton
												aria-label='delete'
												onClick={() =>
													archiveAttachment(tempDoc.attachment._id, 'document')
												}
												size='large'
											>
												<Delete />
											</IconButton>
										)}
									</Grid>
								))}
							</Grid>
						)}
					</Grid>
					<Grid container>
						{!isEmpty(other) && (
							<Grid container direction='row' justifyContent='space-around'>
								<Grid container direction='row'>
									<Typography
										className={classes.heading}
										style={{ marginBottom: '1em' }}
									>
										Other
									</Typography>
								</Grid>
								{other.map(tempOther => (
									<Grid item md={12}>
										<iframe
											title={'extra-content-other'}
											src={`https://docs.google.com/gview?url=${tempOther.attachment.url}&embedded=true`}
										/>
										{user && user.role === 'tmpg' && (
											<IconButton
												aria-label='delete'
												onClick={() =>
													archiveAttachment(tempOther.attachment._id, 'other')
												}
												size='large'
											>
												<Delete />
											</IconButton>
										)}
									</Grid>
								))}
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
		</UserContext.Consumer>
	)
}
ExtraContent.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ExtraContent)
