import { Component } from 'react'
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Input,
  InputLabel,
} from '@mui/material'
import BasicContact from './contact'

class NewEmployee extends Component {
  render() {
    return (
      <div>
        <BasicContact
          vals={this.props.vals}
          setFirstName={this.props.setFirstName}
          setLastName={this.props.setLastName}
          setEmail={this.props.setEmail}
        />
        <FormControl margin='normal' required fullWidth>
          <InputLabel htmlFor='phone'> Phone Number </InputLabel>
          <Input
            id='phone'
            name='phone'
            required={true}
            value={this.props.vals.phone}
            onChange={e => this.props.setPhone(e.target.value)}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.vals.isAdmin}
              value='isAdmin'
              color='primary'
              onChange={e => this.props.setIsAdmin(e.target.checked)}
            />
          }
          label='Do they need Admin access?'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.vals.isAccounts}
              value='isAccounts'
              color='primary'
              onChange={e => this.props.setIsAccounts(e.target.checked)}
            />
          }
          label='Are they accounts?'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.vals.isMedia}
              value='isMedia'
              color='primary'
              onChange={e => this.props.setIsMedia(e.target.checked)}
            />
          }
          label='Are they Media?'
        />
      </div>
    )
  }
}

export default NewEmployee
