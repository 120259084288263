import { Component } from 'react'
import MaterialTable from 'material-table'
import { Delete, Create, Cached } from '@mui/icons-material'
import api from '../api/api'
import EditListUser from './editListUser'
import {
  CssBaseline,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import { UserContext } from '../userContext'

class UsersList extends Component {
  state = {
    users: [],
    loading: true,
    selectedUser: {},
    open: false,
  }

  async componentDidMount() {
    const users = await api.getUsers()
    this.setState({ loading: false, users: users })
  }

  onArchiveClick = async (rowData) => {
    const userId = rowData._id
    try{
      const updatedUser = await api.archiveUser(userId)
      this.setState((prevState) => {
        let { users } = prevState
        users.forEach((user) => {
          if (user._id === updatedUser._id) {
            user.status = 'deactivated'
          }
        })
        return users
      })
    } catch (err){
      throw err
    }
    
  }
  onReactivateClick = async (rowData) => {
    const userId = rowData._id
    try {
      const updatedUser = await api.reactivateUser(userId)
      this.setState((prevState) => {
        let { users } = prevState
        users.forEach((user) => {
          if (user._id === updatedUser._id) {
            user.status = 'invited'
          }
        })
        return users
      })
    } catch (err) {
      throw err
    }
  }

  editUser = (rowData) => {
    this.setState(
      {
        selectedUser: rowData,
      },
      this.setState({ open: true })
    )
  }
  closeDialog() {
    this.setState({ open: false })
  }

  render() {
    if (this.state.loading) {
      return <h1>loading...</h1>
    } else {
      return (
        <UserContext.Consumer>
          {({ user }) => (
            <Grid style={{ margin: 20 }}>
              <CssBaseline />
              <MaterialTable
                title='Users'
                columns={[
                  { title: 'Type', field: 'role' },
                  { title: 'First Name', field: 'firstName' },
                  { title: 'Last Name', field: 'lastName' },
                  { title: 'Email', field: 'email' },
                  { title: 'Status', field: 'status' },
                ]}
                data={this.state.users}
                actions={
                  user && user.isAdmin
                    ? [
                        {
                          icon: Create,
                          tooltip: 'Edit User',
                          onClick: (event, rowData) => this.editUser(rowData),
                        },
                        {
                          icon: Delete,
                          tooltip: 'Deactivate User',
                          onClick: (event, rowData) =>
                            this.onArchiveClick(rowData),
                        },
                        {
                          icon: Cached,
                          tooltip: 'Reinvite/reactivate User',
                          onClick: (event, rowData) =>
                            this.onReactivateClick(rowData),
                        },
                      ]
                    : []
                }
                options={{
                  grouping: true,
                  exportButton: true,
                  filtering: true,
                  actionsColumnIndex: -1,
                  pageSize: 20,
                }}
              />
              <Dialog open={this.state.open} onClose={() => this.closeDialog()}>
                <DialogTitle>
                  Edit{' '}
                  {this.state.selectedUser !== undefined
                    ? this.state.selectedUser.firstName
                    : ''}
                </DialogTitle>
                <DialogContent>
                  <EditListUser
                    selectedUser={this.state.selectedUser}
                    setState={this.setState.bind(this)}
                    closeDialog={this.closeDialog.bind(this)}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
          )}
        </UserContext.Consumer>
      )
    }
  }
}

export default UsersList
