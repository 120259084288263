import { useState } from 'react'
import { Grid, Button, Input, InputLabel } from '@mui/material'
import api from '../api/api'

const CreateStation = ({ closeDialog, setRefetch }) => {
  const [station, setStation] = useState('')
  const [market, setMarket] = useState('')
  const [format, setFormat] = useState('')
  const [owner, setOwner] = useState('')

  const onSubmit = async () => {
    try {
      await api.createStation({ station, market, format, owner })
      closeDialog()
      setRefetch(true)
    } catch (err) {
      throw err
    }
  }

  return (
    <Grid container>
      <Grid column>
        <Grid container>
          <Grid item>
            <InputLabel>Call Number</InputLabel>
            <Input
              style={{ textTransform: 'uppercase' }}
              name='station'
              required={true}
              onChange={(e) => setStation(e.target.value.toUpperCase())}
              value={station}
            />
          </Grid>
          <Grid item>
            <InputLabel>Market</InputLabel>
            <Input
              name='market'
              required={true}
              onChange={(e) => setMarket(e.target.value)}
              value={market}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <InputLabel>Format</InputLabel>
            <Input
              name='format'
              required={true}
              onChange={(e) => setFormat(e.target.value)}
              value={format}
            />
          </Grid>
          <Grid item>
            <InputLabel>Owner</InputLabel>
            <Input
              name='owner'
              required={true}
              onChange={(e) => setOwner(e.target.value)}
              value={owner}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Button type='submit' onClick={() => closeDialog()}>
              {' '}
              Cancel{' '}
            </Button>
          </Grid>
          <Grid item>
            <Button type='submit' onClick={() => onSubmit()}>
              {' '}
              Submit{' '}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateStation
