import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import ArchiveIcon from '@mui/icons-material/Archive'
import PageviewIcon from '@mui/icons-material/Pageview'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate'
import api from '../api/api'
import { CssBaseline, Grid } from '@mui/material'
import { UserContext } from '../userContext'
import { ToastError } from '../components/notifications/Toasts'

const Dashboard = () => {
	const navigate = useNavigate()
	const [programs, setPrograms] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		getPrograms()
	}, [])

	const getPrograms = async () => {
		try {
			const programs = await api.getPrograms()
			setPrograms(programs)
			setLoading(false)
		} catch (err) {
			ToastError({
				message: 'Error fetching programs'
			})
		}
	}

	const onArchiveClick = async programId => {
		setLoading(true)
		try {
			await api.archiveProgram(programId)
			getPrograms()
		} catch (err) {
			ToastError({
				message: 'Error archiving program'
			})
		}
	}

	const duplicate = async id => {
		try {
			await api.duplicateProgram(id)
			getPrograms()
		} catch (err) {
			ToastError({
				message: 'Error duplicating program'
			})
		}
	}

	if (loading) return <h1>loading..</h1>
	else {
		return (
			<UserContext.Consumer>
				{({ user }) => (
					<Grid style={{ margin: 20 }}>
						<CssBaseline />
						<MaterialTable
							title='Programs'
							columns={[
								{
									title: 'Program Name',
									field: 'name',
									render: rowData => (
										<Link
											to={`/view/program/${rowData._id}`}
											style={{ textDecoration: 'none', color: 'black' }}
										>
											{rowData.name}
										</Link>
									)
								},
								{ title: 'Product', field: 'product' },
								{ title: 'Brand', field: 'issuingBrand' },
								{ title: 'Aircheck', field: 'aircheck', type: 'boolean' },
								{ title: 'Status', field: 'status' }
							]}
							data={programs}
							actions={
								user && user.isAdmin
									? [
											{
												icon: PageviewIcon,
												tooltip: 'View',
												onClick: (e, rowData) =>
													navigate(`/view/program/${rowData._id}`)
											},
											{
												icon: ArchiveIcon,
												tooltip: 'Archive',
												onClick: (e, rowData) => onArchiveClick(rowData._id)
											},
											{
												icon: ControlPointDuplicateIcon,
												tooltip: 'Duplicate',
												onClick: (e, rowData) => duplicate(rowData._id)
											}
									  ]
									: [
											{
												icon: PageviewIcon,
												tooltip: 'View',
												onClick: (e, rowData) =>
													navigate(`/view/program/${rowData._id}`)
											}
									  ]
							}
							options={{
								actionsColumnIndex: -1,
								exportButton: true,
								filtering: true,
								pageSize: 20
							}}
						/>
					</Grid>
				)}
			</UserContext.Consumer>
		)
	}
}

export default Dashboard
