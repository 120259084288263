import { Component } from 'react'
import { Grid, Button, Input, Select, MenuItem } from '@mui/material'
import api from '../api/api'

class EditUser extends Component {
  state = {
    currentValue: this.props.currentValue,
  }

  handleChange = (event) => {
    this.setState({ currentValue: event.target.value })
  }

  onSubmit = async (e) => {
    let userId = this.props.userId
    let args = { [this.props.field]: this.state.currentValue }
    try {
      await api.editUser(userId, args)
      let { user } = this.props
      user[this.props.field] = this.state.currentValue
      this.props.setUser({ user })
      this.props.closeDialog()
    } catch (err) {
      throw err
    }
  }

  render() {
    const { field } = this.props
    return (
      <Grid container>
        {field === 'notification' ? (
          <Select
            name='currentValue'
            value={this.state.currentValue}
            onChange={this.handleChange}
          >
            <MenuItem value='text'>text</MenuItem>
            <MenuItem value='email'>email</MenuItem>
            <MenuItem value='both'>text & email</MenuItem>
          </Select>
        ) : (
          <Input
            name='currentValue'
            onChange={this.handleChange}
            value={this.state.currentValue}
          />
        )}
        <Button type='submit' onClick={this.onSubmit}>
          {' '}
          Update{' '}
        </Button>
      </Grid>
    )
  }
}

export default EditUser
