import { Component } from 'react'
import BasicContact from './contact'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'

class NewCompany extends Component {
  render() {
    return (
      <div>
        <BasicContact
          vals={this.props.vals}
          setFirstName={this.props.setFirstName}
          setLastName={this.props.setLastName}
          setEmail={this.props.setEmail}
        />
        <FormControl margin='normal' required fullWidth>
          <InputLabel htmlFor='company'> Company </InputLabel>
          <Input
            name='company'
            type='text'
            id='company'
            required={true}
            value={this.props.vals.company}
            onChange={e => this.props.setCompany(e.target.value)}
          />
        </FormControl>
      </div>
    )
  }
}

export default NewCompany
