import { useState } from 'react'
import PropTypes from 'prop-types'
import api from '../../api/api'
import { Grid, Typography, IconButton, Button } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Delete } from '@mui/icons-material'
import SpotUploader from './spotUploader'
import SpotTable from './spotTable'
import RepSpotTable from './repSpotTable'
import moment from 'moment'

const styles = theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		textAlign: 'left'
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	}
})

const SpotPanel = ({
	role,
	spotsLoading,
	spots,
	setSpots,
	station,
	program
}) => {
	const [localProgram, setLocalProgram] = useState(program)

	const archiveAttachment = async (id, spotType) => {
		try {
			const res = await api.archiveAttachment(localProgram._id, id, spotType)
			setLocalProgram(res)
		} catch (err) {
			alert(err)
		}
	}

	const uploadAttachment = async (name, file) => {
		const data = new FormData()
		data.append(name, file)
		try {
			const res = await api.updateProgramSpotDocs(localProgram._id, name, data)
			setLocalProgram(res)
		} catch (err) {
			alert(err)
		}
	}

	const usableNames = {
		':15 DJ Endorsements': 'DJ_15_Endorsements',
		':30 DJ Endorsements': 'DJ_30_Endorsements',
		':60 DJ Endorsements': 'DJ_60_Endorsements',
		':15 Brand Spot': 'Brand_15_Spot',
		':30 Brand Spot': 'Brand_30_Spot',
		':60 Brand Spot': 'Brand_60_Spot',
		'Brand Centric Promos': 'brandCentricPromos',
		'Sweepstakes Promos': 'sweepstakesPromos',
		'Appearance Promos': 'appearancePromos',
		'Call-ins': 'callins',
		'Sponsorship / Brand Mention': 'sponsorshipBrandMention',
		'N_W_TSpot': 'N_W_TSpot',
		'Vignettes': 'vignettes',
		'Interviews': 'interviews',
		'Custom': 'custom'
	}

	return (
		<Grid container direction='row'>
			{Object.entries(usableNames).map(([key, value]) =>
				localProgram[value] && localProgram[value].requiredSpot ? (
					<Grid item md={12} style={{ marginBottom: '1em', padding: '1em 0' }}>
						<Grid container direction='row' style={{ marginBottom: '1em' }}>
							<Typography variant='h6'>{key}</Typography>
						</Grid>
						<Typography variant='paragraph' color='primary'>
							Due Date:{' '}
							{localProgram[value].dueDate
								? moment(localProgram[value].dueDate).format('MM/DD/YYYY')
								: 'N/A'}
						</Typography>
						{role !== 'client' && (
							<Grid
								container
								direction='row'
								style={{ borderBottom: 'lightgrey 1px solid' }}
							>
								<Grid item md={8} style={{ padding: '1em' }}>
									<Typography align='left'>
										Notes:{' '}
										{localProgram[value].script
											? localProgram[value].script
											: 'N/A'}
									</Typography>
								</Grid>
								<Grid item md={4}>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										Documents:{' '}
										{localProgram[value].attachment &&
										localProgram[value].attachment.active ? (
											localProgram[value].attachment.filename
										) : (
											<>
												{role === 'tmpg' ? (
													<form enctype='multipart/form-data'>
														<Button
															color='primary'
															component='label'
															variant='contained'
															size='small'
															style={{
																color: 'white',
																marginLeft: '1rem'
															}}
														>
															Upload Document
															<input
																type='file'
																name={`${value}_file`}
																onChange={event =>
																	uploadAttachment(
																		event.target.name,
																		event.target.files[0]
																	)
																}
																style={{ display: 'none' }}
															/>
														</Button>
													</form>
												) : (
													'N/A'
												)}
											</>
										)}
									</div>
									{localProgram[value].attachment &&
										localProgram[value].attachment.active && (
											<Grid
												container
												direction='row'
												justifyContent={'space-around'}
												alignContent={'center'}
											>
												<Grid item md={9}>
													<iframe
														title={value}
														src={`https://docs.google.com/gview?url=${localProgram[value].attachment.url}&embedded=true`}
													/>
												</Grid>
												<Grid item md={1}>
													{role === 'tmpg' && (
														<IconButton
															aria-label='delete'
															onClick={() => {
																archiveAttachment(
																	localProgram[value].attachment._id,
																	value
																)
															}}
															size='large'
														>
															<Delete />
														</IconButton>
													)}
												</Grid>
											</Grid>
										)}
								</Grid>
							</Grid>
						)}
					</Grid>
				) : (
					''
				)
			)}
			<Grid container direction='row' justifyContent='center'>
				<SpotUploader
					program={localProgram}
					setSpots={setSpots}
					role={role}
					station={station}
					spots={spots}
				/>
			</Grid>
			<Grid container direction='row' style={{ marginBottom: '1em' }}>
				{!spotsLoading ? (
					role === 'rep' ? (
						<RepSpotTable spots={spots} />
					) : (
						<SpotTable spots={spots} setSpots={setSpots} role={role} />
					)
				) : (
					''
				)}
			</Grid>
		</Grid>
	)
}

SpotPanel.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SpotPanel)
