import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import PageviewIcon from '@mui/icons-material/Pageview'
import api from '../api/api'
import { CssBaseline, Grid } from '@mui/material'

const ClientDashboard = ({ user }) => {
  const [programs, setPrograms] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    getPrograms()
  }, [])

  const getPrograms = async () => {
    try {
      const programs = await api.getProgramsByClient(user._id)
      setPrograms(programs)
      setLoading(false)
    } catch (err) {
      throw err
    }
  }

  if (loading) {
    return <h1>loading..</h1>
  } else {
    return (
      <Grid style={{ margin: 20 }}>
        <CssBaseline />
        <MaterialTable
          title='Programs'
          columns={[
            {
              title: 'ProgramName',
              field: 'name',
              render: (rowData) => (
                <Link
                  to={`/view/program/${rowData._id}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {rowData.name}
                </Link>
              ),
            },
            { title: 'Aircheck', field: 'aircheck', type: 'boolean' },
            {
              title: 'Start Date',
              field: 'rotationDates[0].startDate',
              type: 'numeric',
            },
            {
              title: 'End Date',
              field: 'rotationDates[0].endDate',
              type: 'numeric',
            },
            { title: 'Status', field: 'status' },
          ]}
          data={programs}
          actions={[
            {
              icon: PageviewIcon,
              tooltip: 'View Program',
              onClick: (event, rowData) =>
                navigate(`/view/program/${rowData._id}`),
            },
          ]}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </Grid>
    )
  }
}

export default ClientDashboard
