import { Component } from 'react'
import { Grid, Button, Select, MenuItem } from '@mui/material'
import Markets from '../components/programs/markets'
import api from '../api/api'

class EditListUser extends Component {
	state = {
		role: this.props.selectedUser.role,
		permissions: this.props.selectedUser.permissions,
		stations: this.props.selectedUser.stations,
		markets: this.props.selectedUser.markets
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	onSubmit = async e => {
		let userId = this.props.selectedUser._id
		try {
			await api.editUser(userId, this.state)
			this.props.setState(prevState => {
				const { users } = prevState
				const index = users.findIndex(x => x._id === userId)
				users[index].role = this.state.role
				users[index].permissions = this.state.permissions
				users[index].stations = this.state.stations
				users[index].markets = this.state.markets
				return prevState
			}, this.props.closeDialog())
		} catch (err) {
			throw err
		}
	}
	setMarkets = val => {
		this.setState({ markets: val })
	}
	setStations = val => {
		this.setState({ stations: val })
	}

	render() {
		const { role, permissions, markets, stations } = this.state
		return (
			<Grid container>
				<Select name='role' onChange={this.handleChange} value={role}>
					<MenuItem value='tmpg'>TMPG</MenuItem>
					<MenuItem value='client'>Client</MenuItem>
					<MenuItem value='rep'>Rep</MenuItem>
				</Select>
				{role === 'tmpg' ? (
					<Select
						name='permissions'
						onChange={this.handleChange}
						value={permissions}
					>
						<MenuItem value='admin'>Admin</MenuItem>
						<MenuItem value='client'>Standard</MenuItem>
					</Select>
				) : role === 'rep' ? (
					<Markets
						currentMarkets={markets}
						currentStations={stations}
						setProgramMarkets={this.setMarkets}
						setProgramStations={this.setStations}
					/>
				) : (
					''
				)}
				<Button type='submit' onClick={this.onSubmit}>
					{' '}
					Update{' '}
				</Button>
			</Grid>
		)
	}
}

export default EditListUser
