import { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { Create } from '@mui/icons-material'
import api from '../api/api'
import {
  CssBaseline,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material'
import EditStation from './editStation'
import CreateStation from './createStation'
import { UserContext } from '../userContext'

const Stations = () => {
  const [stations, setStations] = useState([])
  const [loading, setLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const [addStation, setAddStation] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [selectedStation, setSelectedStation] = useState('')

  useEffect(() => {
    getStations()
  }, [refetch])

  const getStations = async () => {
    setLoading(true)
    try {
      const stationList = await api.getStations()
      setStations(stationList)
      setLoading(false)
      if (refetch) setRefetch(false)
    } catch (err) {
      throw err
    }
  }

  const closeEdit = () => {
    setEdit(false)
  }

  const closeStation = () => {
    setAddStation(false)
  }

  const editStation = (rowData) => {
    setSelectedStation(rowData)
    setEdit(true)
  }

  if (loading) {
    return <h1>loading...</h1>
  } else {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <Grid style={{ margin: 20 }}>
            <CssBaseline />
            <Grid row>
              <Button onClick={() => setAddStation(true)}>
                Add new station
              </Button>
              <Dialog open={addStation} onClose={() => closeStation()}>
                <DialogTitle>Add New Station</DialogTitle>
                <DialogContent>
                  <CreateStation
                    closeDialog={closeStation}
                    setRefetch={setRefetch}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
            <MaterialTable
              title='Stations'
              columns={[
                { title: 'Market', field: 'market', defaultGroupOrder: 0 },
                { title: 'Station', field: 'station' },
                { title: 'Format', field: 'format' },
                { title: 'Owner', field: 'owner' },
              ]}
              data={stations}
              actions={
                user && user.isAdmin
                  ? [
                      {
                        icon: Create,
                        tooltip: 'Edit Station',
                        onClick: (event, rowData) => editStation(rowData),
                      },
                    ]
                  : []
              }
              options={{
                grouping: true,
                exportButton: true,
                filtering: true,
                actionsColumnIndex: -1,
                pageSize: 20,
              }}
            />
            <Dialog open={edit} onClose={() => closeEdit()}>
              <DialogTitle>
                Edit{' '}
                {selectedStation !== undefined ? selectedStation.station : ''}
              </DialogTitle>
              <DialogContent>
                <EditStation
                  station={selectedStation}
                  setRefetch={setRefetch}
                  closeDialog={closeEdit}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        )}
      </UserContext.Consumer>
    )
  }
}

export default Stations
