import axios from 'axios'

const api = {
  getSpots: async (programId) => {
    try {
      const response = await axios.get(`/api/spots/${programId}`)
      console.log(response.data)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  getSpotsByStation: async (programId, station) => {
    try {
      const response = await axios.get(`/api/spots/${programId}/${station}`)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  getSpot: async function(programId, id) {
    try {
      const response = await axios.get(`/api/spots/${id}/${programId}`)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  createSpot: async function(spotAttributes) {
    try {
      const response = await axios.post(`/api/spots/`, spotAttributes)
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  updateSpot: async function(programId, spotId, spotAttributes) {
    try {
      const response = await axios.put(
        `/api/spots/${spotId}/${programId}`,
        spotAttributes
      )
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  rejectSpot: async (programId, spotId, commentAttributes) => {
    try {
      const response = await axios.put(
        `/api/spots/${spotId}/reject/${programId}`,
        commentAttributes
      )
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  approveSpot: async (programId, spotId) => {
    try {
      const response = await axios.put(
        `/api/spots/${spotId}/approve/${programId}`
      )
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
  deleteSpot: async (programId, spotId) => {
    try {
      const response = await axios.put(
        `/api/spots/${spotId}/delete/${programId}`
      )
      return response.data
    } catch ({ response }) {
      return response.data
    }
  },
}

export default api
