import { Component } from 'react'
import {
	Grid,
	Button,
	Typography,
	FormLabel,
	Switch,
	FormControlLabel,
	InputLabel,
	Input,
	CssBaseline
} from '@mui/material'
import { includes, isEmpty } from 'lodash'
import DateRangePicker from 'react-daterange-picker'
import dayjs from 'dayjs'

class SelectDates extends Component {
	state = {
		datesPushed: false,
		program: [],
		market: [],
		station: []
	}
	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleInputChange = event => {
		this.setState(
			event.target.value === ''
				? ''
				: { [event.target.name]: Number(event.target.value) }
		)
	}

	pushDates = event => {
		const { market, program, station } = this.state
		let dateRanges = []
		program.forEach(set => {
			let tempObj = {}
			tempObj.startDate = dayjs(set.date.start._d).format('MM-DD-YYYY')
			tempObj.endDate = dayjs(set.date.end._d).format('MM-DD-YYYY')
			dateRanges.push(tempObj)
		})
		market.forEach(set => {
			let tempObj = {}
			tempObj.startDate = dayjs(set.date.start._d).format('MM-DD-YYYY')
			tempObj.endDate = dayjs(set.date.end._d).format('MM-DD-YYYY')
			tempObj.market = set.market
			dateRanges.push(tempObj)
		})
		station.forEach(set => {
			let tempObj = {}
			tempObj.startDate = dayjs(set.date.start._d).format('MM-DD-YYYY')
			tempObj.endDate = dayjs(set.date.end._d).format('MM-DD-YYYY')
			tempObj.station = set.station
			dateRanges.push(tempObj)
		})
		this.props.setRotationDates({ rotationDates: dateRanges })
		this.setState({ datesPushed: true })
	}

	handleDate = (event, index) => {
		this.setState(prevState => {
			const { program } = prevState

			if (!isEmpty(program[index])) {
				program[index].date = event
			} else {
				program.splice(index, 0, { date: event })
			}
			return program
		})
	}

	handleMarketDate = (event, tempMarket, index) => {
		this.setState(prevState => {
			const { market } = prevState
			if (!([tempMarket] in market)) {
				market[tempMarket] = []
			}
			if (!isEmpty(market[tempMarket][index])) {
				market[tempMarket][index].date = event
			} else {
				market[tempMarket].splice(index, 0, {
					date: event,
					market: tempMarket
				})
			}
			return market
		})
	}

	handleStationDate = (event, tempStation, index) => {
		this.setState(prevState => {
			const { station } = prevState
			if (!([tempStation] in station)) {
				station[tempStation] = []
			}
			if (!isEmpty(station[tempStation][index])) {
				station[tempStation][index].date = event
			} else {
				station[tempStation].splice(index, 0, {
					date: event,
					station: tempStation
				})
			}
			return station
		})
	}
	getValue = (field, index) => {
		if (this.state.market[field]) {
			if (this.state.market[field][index]) {
				return this.state.market[field][index].date
			}
		}
	}
	getStationValue = (field, index) => {
		if (this.state.station[field]) {
			if (this.state.station[field][index]) {
				return this.state.station[field][index].date
			}
		}
	}
	render() {
		const { vals } = this.props
		return (
			<Grid container display='row' style={{ margin: 20 }}>
				<CssBaseline />
				<Grid item sm={12}>
					<InputLabel>Date of Rotation</InputLabel>
					<FormControlLabel
						control={
							<Switch
								checked={this.props.variesByMarket}
								onChange={e => this.props.setVariesByMarket(e.target.checked)}
								value='variesByMarket'
							/>
						}
						label='Flights by Market'
					/>
					<FormControlLabel
						control={
							<Switch
								checked={this.props.variesByStation}
								onChange={e => this.props.setVariesByStation(e.target.checked)}
								value='variesByStation'
							/>
						}
						label='Flights by Station'
					/>
				</Grid>
				<Grid container display='row' spacing={2} style={{ margin: 20 }}>
					<Grid container display='row'>
						<FormLabel component='legend'>
							Program Flights
							<Input
								name='program.flights'
								value={this.state['program.flights']}
								margin='dense'
								onChange={this.handleInputChange}
								inputProps={{
									'step': 1,
									'min': 1,
									'max': 9,
									'type': 'number',
									'aria-labelledby': 'input-slider'
								}}
								style={{ marginLeft: 10 }}
							/>
						</FormLabel>
					</Grid>
					{[...Array(this.state[`program.flights`])].map((flight, index) => (
						<Grid container display='row'>
							<DateRangePicker
								numberOfCalendars={2}
								selectionType='range'
								minimumDate={new Date()}
								value={
									this.state.program[index] !== undefined
										? this.state.program[index].date
										: this.state.program[index]
								}
								onSelect={event => this.handleDate(event, index)}
							/>
						</Grid>
					))}
				</Grid>
				{this.props.variesByMarket ? (
					vals.markets.map((market, index) => (
						<Grid
							container
							key={index}
							display='row'
							style={{ margin: 20 }}
							spacing={2}
						>
							<Grid container display='row'>
								<Grid item sm={4}>
									<Typography>{market}</Typography>
								</Grid>
								<Grid item sm={4}>
									<Typography id='input-slider' gutterBottom>
										Flights per market:
									</Typography>
								</Grid>
								<Input
									name={`${market}.flights`}
									value={this.state[`${market}.flights`]}
									margin='dense'
									onChange={this.handleInputChange}
									inputProps={{
										'step': 1,
										'min': 1,
										'max': 10,
										'type': 'number',
										'aria-labelledby': 'input-slider'
									}}
								/>
							</Grid>
							{[...Array(this.state[`${market}.flights`])].map(
								(flight, index) => (
									<Grid container display='row' key={flight}>
										<Grid container display='row'>
											<Typography>Flight #{index + 1}</Typography>
										</Grid>
										<Grid item sm={12}>
											<DateRangePicker
												numberOfCalendars={2}
												selectionType='range'
												minimumDate={new Date()}
												value={this.getValue(market, index)}
												onSelect={event =>
													this.handleMarketDate(event, market, index)
												}
											/>
										</Grid>
									</Grid>
								)
							)}
							{this.props.variesByStation
								? vals.stations.map((station, index) =>
										station.market === market ? (
											<Grid container display='row' key={station.station}>
												<Grid item sm={4}>
													<Typography id='input-slider' gutterBottom>
														Flights per station {station.station}:
													</Typography>
												</Grid>
												<Input
													name={`${market}.${station.station}.flights`}
													value={
														this.state[`${market}.${station.station}.flights`]
													}
													margin='dense'
													onChange={this.handleInputChange}
													inputProps={{
														'step': 1,
														'min': 1,
														'max': 10,
														'type': 'number',
														'aria-labelledby': 'input-slider'
													}}
												/>
												{[
													...Array(
														this.state[`${market}.${station.station}.flights`]
													)
												].map((Stationflight, index) => (
													<Grid container display='row' key={Stationflight}>
														<Grid container display='row'>
															<Typography>
																{station.station} Flight #{index + 1}
															</Typography>
														</Grid>
														<DateRangePicker
															numberOfCalendars={2}
															selectionType='range'
															minimumDate={new Date()}
															value={this.getStationValue(
																station.station,
																index
															)}
															onSelect={event =>
																this.handleStationDate(
																	event,
																	station.station,
																	index
																)
															}
														/>
													</Grid>
												))}
											</Grid>
										) : (
											''
										)
								  )
								: ''}
						</Grid>
					))
				) : this.props.variesByStation &&
				  !this.props.variesByMarket &&
				  !isEmpty(vals.stations) ? (
					<Grid container display='row'>
						<Typography>Stations</Typography>
						{vals.stations.map(station =>
							includes(vals.markets, station.market) ? (
								<Grid container display='row' key={station.station}>
									<Grid item sm={4}>
										<Typography id='input-slider' gutterBottom>
											Flights per station {station.station}:
										</Typography>
									</Grid>
									<Input
										name={`${station.market}.${station.station}.flights`}
										value={
											this.state[`${station.market}.${station.station}.flights`]
										}
										margin='dense'
										onChange={this.handleInputChange}
										inputProps={{
											'step': 1,
											'min': 1,
											'max': 10,
											'type': 'number',
											'aria-labelledby': 'input-slider'
										}}
									/>
									{[
										...Array(
											this.state[`${station.market}.${station.station}.flights`]
										)
									].map((Stationflight, index) => (
										<Grid container display='row' key={Stationflight}>
											<Grid container display='row'>
												<Typography>
													{station.station} Flight #{index + 1}
												</Typography>
											</Grid>
											<DateRangePicker
												numberOfCalendars={2}
												selectionType='range'
												minimumDate={new Date()}
												value={this.getStationValue(station.station, index)}
												onSelect={event =>
													this.handleStationDate(event, station.station, index)
												}
											/>
										</Grid>
									))}
								</Grid>
							) : (
								''
							)
						)}
					</Grid>
				) : (
					''
				)}
				<Button
					onClick={() => this.pushDates()}
					variant='contained'
					size='small'
					color={this.state.datesPushed ? 'info' : 'primary'}
					style={
						this.state.datesPushed
							? { backgroundColor: '#008100', color: 'white' }
							: {}
					}
				>
					confirm date ranges
				</Button>
			</Grid>
		)
	}
}

export default SelectDates
