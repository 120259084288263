import { useState } from 'react'
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'
import api from '../../api/api'

export const SpotCommentDialog = ({
	spot,
	handleClose,
	spots,
	setSpots,
	isOpen
}) => {
	const [commentText, setCommentText] = useState('')

	const handleSubmit = async event => {
		const spotId = spot._id
		const programId = spot.programId

		try {
			const updatedSpot = await api.rejectSpot(programId, spotId, {
				comment: commentText
			})
			let temp = [...spots]
			temp[temp.findIndex(i => i._id === updatedSpot._id)] = updatedSpot
			setSpots(temp)
		} catch (err) {
			console.error(err)
		}
		handleClose()
	}

	const handleOnChange = async event => {
		setCommentText(event.target.value)
	}

	const isDisabled = commentText.length <= 0

	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Comment</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please comment as to why you are rejecting this spot.
					</DialogContentText>
					<TextField
						autoFocus
						id='name'
						label='Comment'
						type='text'
						rows='3'
						fullWidth
						multiline
						required
						onChange={handleOnChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleSubmit} disabled={isDisabled} color='primary'>
						Reject Spot
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
