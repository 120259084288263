import { Component } from 'react'
import { DropzoneArea } from 'mui-file-dropzone'
import Grid from '@mui/material/Grid'

class DropzoneAreaExample extends Component {
	render() {
		const { onChange } = this.props

		return (
			<Grid
				container
				direction='row'
				justifyContent='space-around'
				alignItems='center'
			>
				<DropzoneArea
					dropzoneText='Add supporting material for clients here'
					acceptedFiles={[
						'image/*',
						'video/*',
						'application/*',
						'application/vnd.ms-excel',
						'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
						'application/docx',
						'application/pdf',
						'text/plain',
						'application/msword',
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					]}
					filesLimit={10}
					maxFileSize={10000000}
					onChange={onChange.bind(this)}
				/>
			</Grid>
		)
	}
}

export default DropzoneAreaExample
