import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    justifyContent: 'center',
    alignContent: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(
      3
    )}`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
})

  function ErrorMessage(props) {
  const { classes } = props;

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ErrorOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ERROR – Page Not Found. We can’t find the page you’re looking for. You can either return to previous page or go back to the homepage.
        </Typography>
      </Paper>
    </main>
  );
}

ErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorMessage);
