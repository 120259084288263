import { Component } from 'react'
import BasicContact from './contact'
import { Input, InputLabel, FormControl, Typography } from '@mui/material'
import Markets from '../../../components/programs/markets'

class NewRep extends Component {
  render() {
    return (
      <div>
        <BasicContact
          vals={this.props.vals}
          setFirstName={this.props.setFirstName}
          setLastName={this.props.setLastName}
          setEmail={this.props.setEmail}
        />
        <FormControl margin='normal' required fullWidth>
          <InputLabel htmlFor='phone'> Phone Number </InputLabel>
          <Input
            id='phone'
            name='phone'
            required={true}
            value={this.props.vals.phone}
            onChange={e => this.props.setPhone(e.target.value)}
          />
        </FormControl>
        <Typography style={{ marginTop: '1em' }}>
          Selected Market(s):{' '}
          {this.props.vals.markets.map((market) => (
            <span key={market} style={{ paddingLeft: '.5em' }}>
              {market}
            </span>
          ))}
        </Typography>
        <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
          Selected Station(s):
          {this.props.vals.stations.map((station) => (
            <span key={station.station} style={{ paddingLeft: '.5em' }}>
              {station.station}
            </span>
          ))}
        </Typography>
        <Markets
          style={{ paddingLeft: 0, margin: 0 }}
          setStations={this.props.setStations}
          setMarkets={this.props.setMarkets}
        />
      </div>
    )
  }
}

export default NewRep
