import { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import MaterialTable from 'material-table'
import { Grid, Button } from '@mui/material'
import {
	PlayArrow,
	CloudDownload,
	ThumbDown,
	Stop,
	Check,
	DeleteForever
} from '@mui/icons-material'
import ReplaceSpot from './replaceSpot'
import { SpotCommentDialog } from './spotCommentDialog'
import SpotComments from './spotComments'
import api from '../../api/api'

const styles = theme => ({
	commentsActions: {
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(3, 3),
		padding: theme.spacing(2, 2)
	}
})

const audio = new Audio()

const SpotTable = ({ spots, setSpots, classes, role }) => {
	const [open, setOpen] = useState(false)
	const [isRejecting, setIsRejecting] = useState(false)
	const [activeSpot, setActiveSpot] = useState(null)
	const [isDoing, setIsDoing] = useState(false)

	const playSpot = data => {
		audio.src = data.attachment.url
		audio.play()
	}
	const stopSpot = data => {
		audio.src = data.attachment.url
		audio.pause()
	}

	const deleteSpot = async spot => {
		try {
			const deletedSpot = await api.deleteSpot(spot.programId, spot._id)
			let temp = [...spots]
			temp.splice([temp.findIndex(i => i._id === deletedSpot._id)], 1)
			setSpots(temp)
			setIsDoing(false)
		} catch (err) {
			console.error(err)
		}
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleAddComment = spot => {
		setIsRejecting(true)
		setActiveSpot(spot)
	}

	const approveSpot = async spot => {
		try {
			const updatedSpot = await api.approveSpot(spot.programId, spot._id)
			let temp = [...spots]
			temp[temp.findIndex(i => i._id === updatedSpot._id)].status = 'approved'
			setSpots(temp)
			setIsDoing(false)
		} catch (err) {
			throw err
		}
	}

	return (
		<Grid item sm={12} style={{ marginTop: '1em' }}>
			<MaterialTable
				title='Spots'
				data={spots}
				columns={[
					{ title: 'Type', field: 'type' },
					{ title: 'Station', field: 'station' },
					{ title: 'Status', field: 'status' },
					{ title: 'Name', field: 'name' },
					{ title: 'Market', field: 'market' },
					{
						title: 'Upload Date',
						field: 'attachment.createdAt',
						type: 'date'
					}
				]}
				actions={[
					{
						icon: CloudDownload,
						tooltip: 'Download',
						onClick: (event, rowData) => {
							event.preventDefault()
							window.open(rowData.attachment.url, 'download')
						}
					},
					{
						icon: PlayArrow,
						tooltip: 'Play',
						onClick: (event, rowData) => {
							event.preventDefault()
							playSpot(rowData)
						}
					},
					{
						icon: Stop,
						tooltip: 'Stop',
						onClick: (event, rowData) => {
							event.preventDefault()
							stopSpot(rowData)
						}
					},
					{
						icon: Check,
						tooltip: 'Approve Spot',
						onClick: (event, rowData) => {
							setIsDoing(true)
							approveSpot(rowData)
						}
					},
					{
						icon: ThumbDown,
						tooltip: 'Reject Spot',
						onClick: (event, rowData) => {
							event.preventDefault()
							setIsRejecting(true)
							setActiveSpot(rowData)
						}
					},
					role === 'tmpg' && {
						icon: DeleteForever,
						tooltip: 'Delete Spot',
						onClick: (event, rowData) => {
							deleteSpot(rowData)
						}
					}
				]}
				detailPanel={[
					{
						render: rowData => {
							return (
								<div>
									<SpotComments comments={rowData.comments} />
									<div className={classes.commentsActions}>
										<Button
											variant='contained'
											color='primary'
											size='large'
											onClick={() => handleAddComment(rowData)}
										>
											Add Comment
										</Button>
									</div>
								</div>
							)
						}
					}
				]}
				options={{
					grouping: true,
					exportButton: true,
					filtering: true,
					actionsColumnIndex: -1,
					pageSize: 5
				}}
			/>
			<ReplaceSpot
				open={open}
				setOpen={setOpen}
				handleOpen={handleOpen}
				handleClose={handleClose}
			/>
			<SpotCommentDialog
				isOpen={isRejecting}
				spot={activeSpot}
				spots={spots}
				handleClose={() => setIsRejecting(false)}
				setSpots={setSpots}
			/>
		</Grid>
	)
}

export default withStyles(styles)(SpotTable)
