import { useState } from 'react'
import { Grid, Button, DialogTitle, DialogContent, Dialog } from '@mui/material'
import DateRangePicker from 'react-daterange-picker'
import dayjs from 'dayjs'
import 'react-daterange-picker/dist/css/react-calendar.css'

const UpdateDates = props => {
	const [tempDate, setTempDate] = useState(new Date())
	const [open, setOpen] = useState(false)
	const [type, setType] = useState(null)
	const [index, setIndex] = useState(null)

	const handleUpdate = value => {
		props.setDates(dayjs(value).format('MM-DD-YYYY'), type, index)
		setOpen(false)
	}

	const updateDate = (i, t) => {
		setOpen(true)
		setIndex(i)
		setType(t)
	}
	const currentDates = props.rotationDates
	return (
		<Grid container display='row' style={{ margin: 20 }}>
			{currentDates &&
				currentDates.map((range, index) =>
					range.hasOwnProperty('market') ? (
						<Grid
							container
							display='row'
							key={'current' + index}
							style={{ margin: 20 }}
						>
							<Grid container display='row'>
								{range.market} flight:
							</Grid>
							<Grid item md={6}>
								{dayjs(range.startDate).format('MM-DD-YYYY')}{' '}
								<Button
									color='primary'
									onClick={e => updateDate(index, 'startDate')}
								>
									edit
								</Button>
							</Grid>
							<Grid item md={6}>
								{dayjs(range.endDate).format('MM-DD-YYYY')}{' '}
								<Button
									color='primary'
									onClick={e => updateDate(index, 'endDate')}
								>
									edit
								</Button>
							</Grid>
						</Grid>
					) : !range.hasOwnProperty('station') ? (
						<Grid
							container
							display='row'
							key={'station' + index}
							style={{ margin: 20 }}
						>
							<Grid container display='row'>
								{range.station} flight:
							</Grid>
							<Grid item md={6}>
								{dayjs(range.startDate).format('MM-DD-YYYY')}{' '}
								<Button
									color='primary'
									onClick={e => updateDate(index, 'startDate')}
								>
									edit
								</Button>
							</Grid>
							<Grid item md={6}>
								{dayjs(range.endDate).format('MM-DD-YYYY')}{' '}
								<Button
									color='primary'
									onClick={e => updateDate(index, 'endDate')}
								>
									edit
								</Button>
							</Grid>
						</Grid>
					) : (
						<Grid
							container
							display='row'
							key={'null' + index}
							style={{ margin: 20 }}
						>
							<Grid container display='row'>
								Program flight
							</Grid>
							<Grid item md={6}>
								{dayjs(range.startDate).format('MM-DD-YYYY')}{' '}
								<Button
									color='primary'
									onClick={e => updateDate(index, 'startDate')}
								>
									edit
								</Button>
							</Grid>
							<Grid item md={6}>
								{dayjs(range.endDate).format('MM-DD-YYYY')}{' '}
								<Button
									color='primary'
									onClick={e => updateDate(index, 'endDate')}
								>
									edit
								</Button>
							</Grid>
						</Grid>
					)
				)}
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>Change Date</DialogTitle>
				<DialogContent>
					<DateRangePicker
						selectionType='single'
						minimumDate={new Date()}
						value={tempDate}
						onSelect={event => handleUpdate(event)}
					/>
				</DialogContent>
			</Dialog>
		</Grid>
	)
}

export default UpdateDates
