import { Component } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Select,
  MenuItem,
} from '@mui/material'
import api from '../api/api'

class SelectSubUser extends Component {
  state = {
    selectedSubUser: '',
    subUsers: [],
    loading: true,
  }

  componentWillMount = async (event) => {
    const { clientId } = this.props
    const subUsers = await api.getSubUsers(clientId)
    this.setState({ subUsers: subUsers })
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = async (event) => {
    const { programId } = this.props
    const userId = this.state.selectedSubUser._id
    const programs = this.state.selectedSubUser.Programs
    programs.push(programId)
    try {
      await api.updateSubUser(userId, programs)
      this.props.closeSelect()
    } catch (err) {
      throw err
    }
  }
  render() {
    return (
      <Dialog
        open={this.props.openSelect}
        onClose={() => this.props.closeSelect()}
      >
        <DialogTitle>Select one of your existing sub users</DialogTitle>
        <DialogContent>
          {this.state.loading ? (
            <Typography>grabbing users</Typography>
          ) : (
            <Select
              value={this.state.selectSubUser}
              onChange={this.handleChange}
              inputProps={{
                name: 'selectedSubUser',
              }}
            >
              {this.state.subUsers.map((subUser, index) => (
                <MenuItem key={index} value={subUser}>
                  {subUser.firstName}, {subUser.email}
                </MenuItem>
              ))}
            </Select>
          )}
          <Button onSubmit={() => this.onSubmit}>Add to program</Button>
        </DialogContent>
      </Dialog>
    )
  }
}
export default SelectSubUser
