import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import {
	Avatar,
	Button,
	CssBaseline,
	FormControl,
	FormControlLabel,
	Checkbox,
	Input,
	InputLabel,
	Paper,
	Typography
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { LockOutlined } from '@mui/icons-material'
import api from '../api/api'
import { ToastError } from '../components/notifications/Toasts'
import { emailIsValid } from './emailvalidator'

const SignIn = props => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const navigate = useNavigate()

	const [isInvalid, setIsInvalid] = useState(true)

	useEffect(() => {
		setIsInvalid(
			[null, undefined, ''].includes(password) || !emailIsValid(email)
		)
	}, [email, password, isInvalid])

	const onSubmit = async e => {
		e.preventDefault()
		try {
			const res = await api.Login({ email, password })
			navigate('/')
			props.setUser(res)
		} catch (err) {
			if (err.response.status === 401)
				ToastError({
					message:
						'Incorrect Username or Password—Please Try Again. \n Note: Password is case-sensitive.'
				})
			else ToastError({ message: 'An unknown error has occured' })
		}
	}
	const { classes } = props
	return (
		<main className={classes.main}>
			<CssBaseline />
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlined />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Sign in
				</Typography>
				<form className={classes.form}>
					<FormControl margin='normal' required fullWidth>
						<InputLabel htmlFor='email'> Email Address </InputLabel>
						<Input
							id='email'
							name='email'
							autoComplete='email'
							autoFocus
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</FormControl>
					<FormControl margin='normal' required fullWidth>
						<InputLabel htmlFor='password'> Password </InputLabel>
						<Input
							name='password'
							type='password'
							id='password'
							autoComplete='current-password'
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
					</FormControl>
					<FormControlLabel
						control={<Checkbox value='remember' color='primary' />}
						label='Remember me'
					/>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						disabled={isInvalid}
						className={classes.submit}
						onClick={e => onSubmit(e)}
					>
						Sign in
					</Button>
				</form>
				<Button style={{ marginTop: 20 }}>
					<Typography onClick={() => navigate('/reset')}>
						Reset Password
					</Typography>
				</Button>
			</Paper>
		</main>
	)
}

SignIn.propTypes = {
	classes: PropTypes.object.isRequired
}

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'flex', // Fix IE 11 issue.
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
		margin: '2em',
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '30%',
		padding: '2em'
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
})

export default withStyles(styles)(SignIn)
