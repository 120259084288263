import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
	palette: {
		primary: {
			light: '#F29B1C',
			main: '#F15A2C',
			dark: '#A82801',
			contrastText: '#fff'
		},
		secondary: {
			light: '#FFF214',
			main: '#FFC857',
			dark: '#FFA30C',
			contrastText: '#252933'
		},
		info: {
			light: '#00b400',
			main: '#008100',
			dark: '#006800',
			contrastText: '#fff'
		},
		default: {
			light: '#00b400',
			main: '#008100',
			dark: '#006800',
			contrastText: '#fff'
		}
	},
	typography: {
		fontFamily: ['Montserrat'].join(','),
		body1: {
			fontFamily: 'Montserrat'
		},
		body2: {
			fontFamily: 'Montserrat'
		}
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': ['Montserrat']
			}
		}
	}
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={theme}>
			<Router>
				<App />
			</Router>
		</ThemeProvider>
	</StyledEngineProvider>
)
