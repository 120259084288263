import { Component } from 'react'
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@mui/material'
import api from '../api/api'

export default class ClientSelector extends Component {
  state = {
    clients: [],
    client: '',
  }
  async componentWillMount() {
    const data = await api.allClients()
    let temp = []
    for (let client in data) {
      temp.push({
        name: data[client].company,
        id: data[client]._id,
      })
    }
    this.setState({ clients: temp })
  }

  handleClient = (event) => {
    this.setState({ client: event.target.value })
  }

  handleSubmit = () => {
    const selectedClient = this.state.client
    this.props.setState((prevState) => {
      const { p } = prevState
      return (p.client = selectedClient)
    })
    api.updateProgram(this.props.programId, { client: selectedClient })
    this.props.closeDialog()
  }

  render() {
    const { clients } = this.state
    return (
      <Grid container direction='row'>
        <Grid item>
          <FormControl margin='normal' required fullWidth>
            <InputLabel htmlFor='client'> Client </InputLabel>
            <Select value={this.state.client} onChange={this.handleClient}>
              {clients.map((client) => (
                <MenuItem key={client.name} value={client}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={this.handleSubmit}> Add Selected Client </Button>
        </Grid>
      </Grid>
    )
  }
}
